.preloader {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: z('loader');
  background-color: #fff;
  text-align: center;
  font-size: 0;

  &__loader {
    border-radius: 50px;
    line-height: $preloader-spinner-size;
    text-align: center;
    width: $preloader-spinner-size;
    height: $preloader-spinner-size;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
    text-transform: uppercase;
    letter-spacing: 0.05em;

    &:before,
    &:after {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: rgba($preloader-spinner-color, 0.4);
      border-radius: 50px;
      opacity: 0;
    }

    &:before {
      transform: scale(1);
      animation: pulse 2s infinite linear;
    }

    &:after {
      animation: pulse 2s 1s infinite linear;
    }
  }
}

@keyframes pulse {
  0% {
    transform: scale(0.6);
    opacity: 0;
  }
  33% {
    transform: scale(1);
    opacity: 1;
  }
  100% {
    transform: scale(1.4);
    opacity: 0;
  }
}
