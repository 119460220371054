// Term with polarity
.term-w-p {
  &::before {
    font-size: .6em;
    margin-right: 3px;
    vertical-align: middle;
  }

  &--positive {
    &::before {
      content: '\25B2';
      color: $color-green-medium;
    }
  }

  &--negative {
    &::before {
      content: '\25BC';
      color: $color-red-medium;
    }
  }
}

.list.list--terms-w-p {
  li.term-w-p {
    padding-left: 1.8ex;

    &::before {
      position: absolute;
      left: 0;
      top: .6em;
    }
  }
}
