// SIZES
// Base
$ui-base-height: 26px;
$padding-regular: 10px;
$padding-medium: 15px;
$padding-large: 25px;
$border-radius-regular: 5px;
$border-radius-medium: 10px;

// Fonts
// TODO: replace with atomic values like: font-size-100, font-size-200, etc.
$font-size: 14px; // TODO: replace with em
$font-size-very-small: ($font-size - 3);
$font-size-small: ($font-size - 2);
$font-size-smaller: ($font-size - 1);
$font-size-bigger: ($font-size + 1);
$font-size-big: ($font-size + 2);
$font-size-huge: ($font-size + 3);
$font-size-max: ($font-size + 6);

$font-line-height: 1.42857;

$font-weight-thin: 100;
$font-weight-light: 300;
$font-weight-regular: 400;
$font-weight-medium: 500;
$font-weight-bold: 700;
$font-weight-black: 900;

// COLORS
// TODO: Store colors in maps and use variables with map item value:
//  $color-black: map-get($base-theme-colors, "color-black");

// Base
$color-general-light: #f0f2fc;
$color-general-200: #dbe0f8;
$color-general-300: #c6cef4;
$color-general-semi: #b7bcf1;
$color-base: #7673d1;
$color-general: #4d58dc;
$color-general-dark: darken($color-general, 15%);
$color-accent: #fe6231;

// UI
$color-black-500: #1E2028;
$color-black: #27292e;
$color-gray-500: #2d323b; // TODO: go to this notation
$color-black-light: #42464e;
$color-gray: #838689;
$color-gray-halfing: #a3a6ab;
$color-gray-semi: #ced2d7;
$color-gray-light: #e9ebee;
$color-gray-soft: #f1f1f1;
$color-gray-very-light: #fbfbfc;
$color-white: #fff;
$color-purple: $color-general;
$color-blue: #4FA1DD;
$color-green-light: #84D082;
$color-green-medium: #2dbf70;
$color-green: #28aa64;
$color-green-dark: #1f864f;
$color-orange-100: #FFEDCF;
$color-orange-200: #FFC769;
$color-orange-300: darken(desaturate(#FFC769, 2%), 10%);
$color-orange-400: #C88142;
$color-yellow: #fdB338;
$color-red-light: #fbe5e1;
$color-red-medium: #f39f96;
$color-red-text: #c0341d;
$color-red: #FF4E1A;
$color-red-dark: darken($color-red, 5%);

$experiment-tables: (
  increase-lifespan: #cbcbef, // Changes in gene activity affect model organism lifespan
  age-related-change: #c8e5fd, // Age-related changes in gene expression, methylation or protein activity
  gene-intervention-to-vital-processes: #c1e2aa, // Changes in gene activity affect the age-related process
  protein-to-gene: #fbea95, // Regulation of genes associated with aging
  gene-to-progeria: #ffd8a9, // Association of the gene with accelerated aging in humans
  gene-to-longevity-effect: #f5c3c2, // Association of gene variants or expression levels with longevity
  gene-to-additional-evidence: #cacaca // Other evidence of gene association with aging
);

// Gradients
$orange-gradient: linear-gradient(to right, darken(#FFAC5F, 10%) 0%, $color-orange-200 100%);
$pink-blue-gradient: linear-gradient(90deg, #91cefd, #9bb5fa 20%, #a4a4f7 40%, #c2adf5 60%, #d9b5f3 80%, #ebbdf2);

// States variables
$color-hover: darken(#e8ebfd, 2%);
$color-selected: #cfd1f6;
$color-active: lighten($color-orange-300, 30%);
$success-color: $color-green-medium;
$error-color: $color-red-medium;

// Elements variables
// - typography
$color-indent: #575757;

// - borders
$color-borders: $color-gray-light;
$color-border-light: #e3e4e8;
$color-borders-active: darken($color-hover, 10%);

// - links
$color-link-blue: #1b75d0;
$color-link-blue-hover: #3275b8;
$color-link-blue-visited: $color-general-dark;
$color-link-blue-active: #1b75cf;

// - buttons

// - header
$header-announcement-height: $ui-base-height;
$header-height: 64px;

// - preloader
$preloader-spinner-size: 50px;
$preloader-spinner-color: $color-general;

// - dark-panel
$dark-panel-100: #64676f;
$dark-panel-200: #323439;

// - clickable badges
// @Deprecated
$color-clickable-500: #832af5;
$color-clickable-300: #d6b9fc;
$color-clickable-200: #e4d1fd;
$color-clickable-100: #f2e9fe;

// SHADOWS
$box-shadow: 0 1px 4px 0 rgba(5, 5, 5, .14);
$box-shadow-to-bottom: $box-shadow;
$box-shadow-to-top: 0 -1px 4px 0 rgba(5, 5, 5, .14);
$box-shadow-layer-1: 0px 0px 8px 0px rgba(5, 5, 5, .14);
$shadow-for-panel: 0 15px 30px 0 rgb(230, 235, 245, .4), 0 1px 4px 0 rgba($color-black-500, .05);

// PATHS
$path-images: '/assets/images';
$path-fonts: '/assets/fonts';

// Fonts
$path-font-roboto-thin: $path-fonts + '/roboto/thin.woff2';
$path-font-roboto-thin-woff: $path-fonts + '/roboto/thin.woff';
$path-font-roboto-light: $path-fonts + '/roboto/light.woff2';
$path-font-roboto-light-woff: $path-fonts + '/roboto/light.woff';
$path-font-roboto-regular: $path-fonts + '/roboto/regular.woff2';
$path-font-roboto-regular-woff: $path-fonts + '/roboto/regular.woff';
$path-font-roboto-medium: $path-fonts + '/roboto/medium.woff2';
$path-font-roboto-medium-woff: $path-fonts + '/roboto/medium.woff';
$path-font-roboto-bold: $path-fonts + '/roboto/bold.woff2';
$path-font-roboto-bold-woff: $path-fonts + '/roboto/bold.woff';
