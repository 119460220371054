// ALias
$a: '.alias';
.alias {
  display: inline-block;
  vertical-align: middle;
  min-width: 40px;
  padding: 3px 5px;
  font-size: ($font-size-small - 1);
  font-weight: $font-weight-medium;
  white-space: nowrap;
  text-align: center;
  border-radius: 3px;
  margin: 0 5px 5px 0;
  background-color: $color-gray-soft;
  user-select: none;
  transition: color, background-color, .15s;

  // optional nested block for text truncation
  &__overflow {
    display: block;
    max-width: 20ch; // 20 characters
    @include text-overflow;
  }

  &--small {
    min-width: 20px;
    padding: 2px 4px;
    font-size: 10px;
  }

  @include resolution_smartphone-and-tablet {
    min-height: 17px;
    font-size: 10px;
    font-weight: $font-weight-bold;
  }

  // Class for skeleton screens
  &.__skeletal {
    height: 22px;
    min-width: 60px;
  }
}

a#{$a},
button#{$a} {
  color: $color-general;
  background-color: lighten($color-hover, 3%);
  cursor: pointer;

  &:hover,
  &:active {
    background-color: $color-hover;
  }
}

#{$a}#{$a}--green,
#{$a}#{$a}--approval {
  &,
  &:hover,
  &:active {
    background-color: lighten($color-green-light, 20%);
    color: desaturate($color-green, 10%);
  }
}

#{$a}#{$a}--orange,
#{$a}#{$a}--warning {
  &,
  &:hover,
  &:active {
    background-color: lighten($color-orange-200, 5);
    color: $color-orange-400;
  }
}

#{$a}#{$a}--error,
#{$a}#{$a}--red {
  &,
  &:hover,
  &:active {
    background-color: lighten($color-accent, 20);
    color: #985d4b;
  }
}

#{$a}#{$a}--pine {
  &,
  &:hover,
  &:active {
    background-color: lighten(#ABE5C6, 15);
    color: darken(desaturate(#ABE5C6, 10%), 25%);
  }
}

#{$a}#{$a}--blue {
  &,
  &:hover,
  &:active {
    background-color: lighten(#9FC5E8, 15);
    color: darken(desaturate(#9FC5E8, 10%), 15%);
  }
}

#{$a}#{$a}--gray {
  &,
  &:hover,
  &:active {
    background-color: $color-gray-light;
    color: $color-black-light;
  }
}


