$element-base-color: $color-general;

.filters-group {
  width: auto;
  display: flex;
  align-items: flex-start;
  flex-flow: row wrap;
  justify-content: flex-start;

  .filter,
  .btn {
    margin: 0 5px 5px 0;
  }
}

.filter {
  $height: $ui-base-height;
  $icon-size: 20px;
  display: block;
  float: left;
  width: auto;
  height: $height;
  position: relative;
  line-height: $height;
  font-size: 12px;
  transition: background-color .15s ease-in;
  cursor: pointer;
  padding: 0 8px 0 8px;
  border-radius: 15px;
  background-color: lighten($element-base-color, 10%);
  user-select: none;

  &, * {
    color: $color-white;
  }

  .icon {
    position: relative;
    top: -1px;
    vertical-align: middle;
    margin-left: 2px;
    font-size: $icon-size;
    height: $icon-size;
    width: $icon-size;
  }

  &:hover {
    background-color: $element-base-color;
  }

  &.__active {
    .fa {
      transform: scale(1, -1) translateZ(0);
    }

    &,
    &:hover {
      background-color: $element-base-color;
    }
  }
}
