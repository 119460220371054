.toggler {
  display: inline-block;
  width: ($ui-base-height * 2) + 4px;
  height: $ui-base-height;
  cursor: pointer;
  position: relative;
  user-select: none;

  &:after { // TODO: make a ripple mixin
    content: '';
    display: block;
    position: absolute;
    top: -2px;
    right: -2px;
    bottom: -2px;
    left: -2px;
    z-index: 0;
    border-radius: $border-radius-regular;
    background-color: rgba($color-hover, .75);
    opacity: 0;
    transition: color, opacity, .15s ease-in-out;
  }

  &__icon.fa {
    display: inline-block;
    width: $ui-base-height;
    height: $ui-base-height;
    position: relative;
    z-index: 1;
    line-height: $ui-base-height;
    text-align: center;
    font-size: 20px;

    & + & {
      margin-left: 4px;
    }
  }

  &:hover,
  &:active {
    &::after {
      opacity: 1;
    }
  }

  &--def {
    .toggler__icon--def {
      color: $color-general;
    }
    .toggler__icon--alt {
      color: $color-gray;
    }
  }

  &--alt {
    .toggler__icon--def {
      color: $color-gray;
    }
    .toggler__icon--alt {
      color: $color-general;
    }
  }
}
