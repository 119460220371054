@charset "UTF-8";
.fadeIn {
  animation-name: fadeIn;
  animation-duration: 1s;
  animation-fill-mode: both;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
.link {
  font-weight: 500;
  cursor: pointer;
  position: relative;
  color: #4d58dc;
}
.link:hover, .link:active {
  color: #5ca4df;
}
.link--gray {
  color: #7e8191;
}
.link--gray:hover, .link--gray:active {
  color: #656877;
}
.link--white {
  color: #fff;
}
.link--white:hover, .link--white:active {
  color: #e6e6e6;
}
.link--underline {
  text-decoration: underline;
}
.link--anchor, .link--term {
  color: #7e8191;
  border-bottom: 1px dashed currentColor;
}
.link--anchor:hover, .link--anchor:active, .link--term:hover, .link--term:active {
  color: #656877;
}
.link--term {
  font-style: italic;
}
.link--gene {
  color: #1b75d0;
  font-weight: 700;
  text-decoration: underline;
}
.link--gene:hover {
  color: #3275b8;
}
.link--gene:active {
  color: #1b75cf;
}

@font-face {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 100;
  src: local("Roboto Thin"), local("Roboto-Thin"), url("/assets/fonts/roboto/thin.woff2") format("woff2"), url("/assets/fonts/roboto/thin.woff") format("woff");
}
@font-face {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 300;
  src: local("Roboto Light"), local("Roboto-Light"), url("/assets/fonts/roboto/light.woff2") format("woff2"), url("/assets/fonts/roboto/light.woff") format("woff");
}
@font-face {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  src: local("Roboto"), local("Roboto-Regular"), url("/assets/fonts/roboto/regular.woff2") format("woff2"), url("/assets/fonts/roboto/regular.woff") format("woff");
}
@font-face {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 500;
  src: local("Roboto Medium"), local("Roboto-Medium"), url("/assets/fonts/roboto/medium.woff2") format("woff2"), url("/assets/fonts/roboto/medium.woff") format("woff");
}
@font-face {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 700;
  src: local("Roboto Bold"), local("Roboto-Bold"), url("/assets/fonts/roboto/bold.woff2") format("woff2"), url("/assets/fonts/roboto/bold.woff") format("woff");
}
html, body, div, span, applet, object, iframe, h1, h2, h3, h4, h5, h6, p, blockquote, pre, a, abbr, acronym, address, big, cite, code, del, dfn, em, img, ins, kbd, q, s, samp, small, strike, strong, sub, sup, tt, var, center, dl, dt, dd, ol, ul, li, fieldset, form, label, legend, table, caption, tbody, tfoot, thead, tr, th, td, article, aside, canvas, details, embed, figure, figcaption, footer, header, menu, nav, output, ruby, section, summary, time, mark, audio, button {
  margin: 0;
  padding: 0;
  border: 0;
  font: inherit;
  vertical-align: baseline;
  word-wrap: break-word;
  line-height: 1.42857;
  outline: 0;
  background: transparent;
  text-rendering: auto;
}

html {
  min-height: 100%;
}
html * {
  box-sizing: border-box;
  -webkit-tap-highlight-color: rgba(77, 88, 220, 0.15);
}

input:focus,
button:focus {
  outline: none;
  border-color: #4d58dc;
}

body {
  min-height: 100%;
  overflow-y: scroll;
  -webkit-text-size-adjust: none;
  font-family: "Roboto", Arial, Helvetica, sans-serif;
  font-size: 14px;
  color: #27292e;
  font-weight: 400;
  line-height: 1.3;
  background: #fff;
  position: relative;
  -webkit-font-smoothing: antialiased;
  cursor: default;
  touch-action: manipulation;
  box-sizing: border-box;
}

h1, h2, h3, h4, h5, h6 {
  margin: 0;
  padding: 0;
  line-height: 1.3;
  word-break: break-word;
  hyphens: auto;
}

a {
  text-decoration: none;
  color: inherit;
}

button {
  border: 0;
  outline: 0;
  appearance: none;
  cursor: pointer;
}

article, aside, details, figcaption, figure, footer, header, menu, nav, section {
  display: block;
}

blockquote, q {
  quotes: none;
}

ul {
  list-style: none;
}
ul li {
  display: inline-block;
}

ol {
  padding-left: 1.7em;
}

img,
svg {
  display: inline-block;
  width: 100%;
  height: auto;
  vertical-align: middle;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

/* Grid mixins */ /*
 * Миксины для медиа-запросов
 */
/* Основные разрешения
================================================*/
/* Desktop - widescreen
------------------------------------------------*/
/* Desktop - small desktop
------------------------------------------------*/
/* Mobile - tablet
------------------------------------------------*/
/* Mobile - smartphone (Portrait, 320px)
------------------------------------------------*/
/* Дополнительные разрешения
================================================*/
/* Mobile - phone only (Optional, 320px)
------------------------------------------------*/
/* Mobile - phablet (Optional, 480px)
------------------------------------------------*/
/* Брейкпоинты, объединяющие несколько разрешений
------------------------------------------------*/
html {
  min-height: 460px;
}

body {
  display: table;
  height: 100%;
  width: 100%;
  min-width: 320px;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  padding-top: 64px;
}
body, body * {
  box-sizing: border-box;
}
body app-root {
  display: grid;
  height: 100%;
  min-height: calc(100vh - 64px);
  grid-template-rows: 0 auto min-content;
}
body .preloader {
  display: none;
}
body:before {
  content: " ";
  display: none;
  height: 100%;
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 99;
  background-color: rgba(36, 48, 184, 0.15);
  animation-name: fadeIn;
  animation-duration: 1s;
  animation-fill-mode: both;
}
body.body--still, body.body--loading {
  overflow: hidden;
}
@media screen and (min-device-width: 992px) {
  body.body--still, body.body--loading {
    padding-right: 15px;
  }
}
body.body--still:before, body.body--loading:before {
  display: block;
}
@media only screen and (max-width: 991.98px) {
  body.body--search-on-main-page-is-active {
    overflow: hidden;
  }
  body.body--search-on-main-page-is-active:before {
    display: block;
  }
}
body.body--loading .preloader {
  display: block;
}

app-header,
.app-header {
  display: block;
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 998;
}

.__hoverable:after {
  content: " ";
  display: block;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
  border-radius: 10px;
  background-color: rgba(77, 88, 220, 0.15);
  transition: color, opacity, 0.15s ease-in-out;
  opacity: 0;
}
.__hoverable:hover:after, .__hoverable:focus:after {
  opacity: 0.6;
}
.__hoverable:active:after {
  opacity: 0.8;
}

.wrapper {
  width: 100%;
}

.container {
  text-align: left;
  position: relative;
}
.container > header {
  text-align: center;
}

.items-section {
  display: block;
  width: 100%;
  margin: 10px 0;
}

.tabs__tabs {
  width: 100%;
  height: 50px;
  font-size: 0;
  white-space: nowrap;
  margin-bottom: 10px;
  border-bottom: 1px solid #e9ebee;
}
.tabs__tab {
  display: inline-block;
  height: 50px;
  font-size: 14px;
  color: #4d58dc;
  vertical-align: top;
  text-align: center;
  white-space: normal;
  word-wrap: break-word;
  line-height: normal;
  max-width: 100px;
  position: relative;
  cursor: pointer;
  margin-bottom: -2px;
  padding: 8px 5px 16px 8px;
}
.tabs__tab:before {
  content: " ";
  display: block;
  height: 3px;
  background-color: #4d58dc;
  opacity: 0;
  position: absolute;
  bottom: 0;
  left: 5px;
  right: 5px;
  z-index: 0;
}
.tabs__tab--active:before, .tabs__tab:hover:before {
  opacity: 1;
}
.tabs__content {
  padding: 2px 10px;
  max-height: 200px;
  overflow: scroll;
}

.hidden {
  display: none !important;
}

@keyframes animation__appear {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
table {
  border-collapse: collapse;
}

.container,
.container-fluid,
.container-xl,
.container-lg,
.container-md,
.container-sm {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}

@media (min-width: 576px) {
  .container-sm, .container {
    max-width: 540px;
  }
}
@media (min-width: 768px) {
  .container-md, .container-sm, .container {
    max-width: 720px;
  }
}
@media (min-width: 992px) {
  .container-lg, .container-md, .container-sm, .container {
    max-width: 960px;
  }
}
@media (min-width: 1200px) {
  .container-xl, .container-lg, .container-md, .container-sm, .container {
    max-width: 1140px;
  }
}
.row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px;
}

.no-gutters {
  margin-right: 0;
  margin-left: 0;
}
.no-gutters > .col,
.no-gutters > [class*=col-] {
  padding-right: 0;
  padding-left: 0;
}

.col-xl,
.col-xl-auto, .col-xl-12, .col-xl-11, .col-xl-10, .col-xl-9, .col-xl-8, .col-xl-7, .col-xl-6, .col-xl-5, .col-xl-4, .col-xl-3, .col-xl-2, .col-xl-1, .col-lg,
.col-lg-auto, .col-lg-12, .col-lg-11, .col-lg-10, .col-lg-9, .col-lg-8, .col-lg-7, .col-lg-6, .col-lg-5, .col-lg-4, .col-lg-3, .col-lg-2, .col-lg-1, .col-md,
.col-md-auto, .col-md-12, .col-md-11, .col-md-10, .col-md-9, .col-md-8, .col-md-7, .col-md-6, .col-md-5, .col-md-4, .col-md-3, .col-md-2, .col-md-1, .col-sm,
.col-sm-auto, .col-sm-12, .col-sm-11, .col-sm-10, .col-sm-9, .col-sm-8, .col-sm-7, .col-sm-6, .col-sm-5, .col-sm-4, .col-sm-3, .col-sm-2, .col-sm-1, .col,
.col-auto, .col-12, .col-11, .col-10, .col-9, .col-8, .col-7, .col-6, .col-5, .col-4, .col-3, .col-2, .col-1 {
  position: relative;
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
}

.col {
  flex-basis: 0;
  flex-grow: 1;
  max-width: 100%;
}

.row-cols-1 > * {
  flex: 0 0 100%;
  max-width: 100%;
}

.row-cols-2 > * {
  flex: 0 0 50%;
  max-width: 50%;
}

.row-cols-3 > * {
  flex: 0 0 33.3333333333%;
  max-width: 33.3333333333%;
}

.row-cols-4 > * {
  flex: 0 0 25%;
  max-width: 25%;
}

.row-cols-5 > * {
  flex: 0 0 20%;
  max-width: 20%;
}

.row-cols-6 > * {
  flex: 0 0 16.6666666667%;
  max-width: 16.6666666667%;
}

.col-auto {
  flex: 0 0 auto;
  width: auto;
  max-width: 100%;
}

.col-1 {
  flex: 0 0 8.33333333%;
  max-width: 8.33333333%;
}

.col-2 {
  flex: 0 0 16.66666667%;
  max-width: 16.66666667%;
}

.col-3 {
  flex: 0 0 25%;
  max-width: 25%;
}

.col-4 {
  flex: 0 0 33.33333333%;
  max-width: 33.33333333%;
}

.col-5 {
  flex: 0 0 41.66666667%;
  max-width: 41.66666667%;
}

.col-6 {
  flex: 0 0 50%;
  max-width: 50%;
}

.col-7 {
  flex: 0 0 58.33333333%;
  max-width: 58.33333333%;
}

.col-8 {
  flex: 0 0 66.66666667%;
  max-width: 66.66666667%;
}

.col-9 {
  flex: 0 0 75%;
  max-width: 75%;
}

.col-10 {
  flex: 0 0 83.33333333%;
  max-width: 83.33333333%;
}

.col-11 {
  flex: 0 0 91.66666667%;
  max-width: 91.66666667%;
}

.col-12 {
  flex: 0 0 100%;
  max-width: 100%;
}

.order-first {
  order: -1;
}

.order-last {
  order: 13;
}

.order-0 {
  order: 0;
}

.order-1 {
  order: 1;
}

.order-2 {
  order: 2;
}

.order-3 {
  order: 3;
}

.order-4 {
  order: 4;
}

.order-5 {
  order: 5;
}

.order-6 {
  order: 6;
}

.order-7 {
  order: 7;
}

.order-8 {
  order: 8;
}

.order-9 {
  order: 9;
}

.order-10 {
  order: 10;
}

.order-11 {
  order: 11;
}

.order-12 {
  order: 12;
}

.offset-1 {
  margin-left: 8.33333333%;
}

.offset-2 {
  margin-left: 16.66666667%;
}

.offset-3 {
  margin-left: 25%;
}

.offset-4 {
  margin-left: 33.33333333%;
}

.offset-5 {
  margin-left: 41.66666667%;
}

.offset-6 {
  margin-left: 50%;
}

.offset-7 {
  margin-left: 58.33333333%;
}

.offset-8 {
  margin-left: 66.66666667%;
}

.offset-9 {
  margin-left: 75%;
}

.offset-10 {
  margin-left: 83.33333333%;
}

.offset-11 {
  margin-left: 91.66666667%;
}

@media (min-width: 576px) {
  .col-sm {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }
  .row-cols-sm-1 > * {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .row-cols-sm-2 > * {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .row-cols-sm-3 > * {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }
  .row-cols-sm-4 > * {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .row-cols-sm-5 > * {
    flex: 0 0 20%;
    max-width: 20%;
  }
  .row-cols-sm-6 > * {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }
  .col-sm-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }
  .col-sm-1 {
    flex: 0 0 8.33333333%;
    max-width: 8.33333333%;
  }
  .col-sm-2 {
    flex: 0 0 16.66666667%;
    max-width: 16.66666667%;
  }
  .col-sm-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .col-sm-4 {
    flex: 0 0 33.33333333%;
    max-width: 33.33333333%;
  }
  .col-sm-5 {
    flex: 0 0 41.66666667%;
    max-width: 41.66666667%;
  }
  .col-sm-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .col-sm-7 {
    flex: 0 0 58.33333333%;
    max-width: 58.33333333%;
  }
  .col-sm-8 {
    flex: 0 0 66.66666667%;
    max-width: 66.66666667%;
  }
  .col-sm-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }
  .col-sm-10 {
    flex: 0 0 83.33333333%;
    max-width: 83.33333333%;
  }
  .col-sm-11 {
    flex: 0 0 91.66666667%;
    max-width: 91.66666667%;
  }
  .col-sm-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .order-sm-first {
    order: -1;
  }
  .order-sm-last {
    order: 13;
  }
  .order-sm-0 {
    order: 0;
  }
  .order-sm-1 {
    order: 1;
  }
  .order-sm-2 {
    order: 2;
  }
  .order-sm-3 {
    order: 3;
  }
  .order-sm-4 {
    order: 4;
  }
  .order-sm-5 {
    order: 5;
  }
  .order-sm-6 {
    order: 6;
  }
  .order-sm-7 {
    order: 7;
  }
  .order-sm-8 {
    order: 8;
  }
  .order-sm-9 {
    order: 9;
  }
  .order-sm-10 {
    order: 10;
  }
  .order-sm-11 {
    order: 11;
  }
  .order-sm-12 {
    order: 12;
  }
  .offset-sm-0 {
    margin-left: 0;
  }
  .offset-sm-1 {
    margin-left: 8.33333333%;
  }
  .offset-sm-2 {
    margin-left: 16.66666667%;
  }
  .offset-sm-3 {
    margin-left: 25%;
  }
  .offset-sm-4 {
    margin-left: 33.33333333%;
  }
  .offset-sm-5 {
    margin-left: 41.66666667%;
  }
  .offset-sm-6 {
    margin-left: 50%;
  }
  .offset-sm-7 {
    margin-left: 58.33333333%;
  }
  .offset-sm-8 {
    margin-left: 66.66666667%;
  }
  .offset-sm-9 {
    margin-left: 75%;
  }
  .offset-sm-10 {
    margin-left: 83.33333333%;
  }
  .offset-sm-11 {
    margin-left: 91.66666667%;
  }
}
@media (min-width: 768px) {
  .col-md {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }
  .row-cols-md-1 > * {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .row-cols-md-2 > * {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .row-cols-md-3 > * {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }
  .row-cols-md-4 > * {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .row-cols-md-5 > * {
    flex: 0 0 20%;
    max-width: 20%;
  }
  .row-cols-md-6 > * {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }
  .col-md-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }
  .col-md-1 {
    flex: 0 0 8.33333333%;
    max-width: 8.33333333%;
  }
  .col-md-2 {
    flex: 0 0 16.66666667%;
    max-width: 16.66666667%;
  }
  .col-md-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .col-md-4 {
    flex: 0 0 33.33333333%;
    max-width: 33.33333333%;
  }
  .col-md-5 {
    flex: 0 0 41.66666667%;
    max-width: 41.66666667%;
  }
  .col-md-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .col-md-7 {
    flex: 0 0 58.33333333%;
    max-width: 58.33333333%;
  }
  .col-md-8 {
    flex: 0 0 66.66666667%;
    max-width: 66.66666667%;
  }
  .col-md-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }
  .col-md-10 {
    flex: 0 0 83.33333333%;
    max-width: 83.33333333%;
  }
  .col-md-11 {
    flex: 0 0 91.66666667%;
    max-width: 91.66666667%;
  }
  .col-md-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .order-md-first {
    order: -1;
  }
  .order-md-last {
    order: 13;
  }
  .order-md-0 {
    order: 0;
  }
  .order-md-1 {
    order: 1;
  }
  .order-md-2 {
    order: 2;
  }
  .order-md-3 {
    order: 3;
  }
  .order-md-4 {
    order: 4;
  }
  .order-md-5 {
    order: 5;
  }
  .order-md-6 {
    order: 6;
  }
  .order-md-7 {
    order: 7;
  }
  .order-md-8 {
    order: 8;
  }
  .order-md-9 {
    order: 9;
  }
  .order-md-10 {
    order: 10;
  }
  .order-md-11 {
    order: 11;
  }
  .order-md-12 {
    order: 12;
  }
  .offset-md-0 {
    margin-left: 0;
  }
  .offset-md-1 {
    margin-left: 8.33333333%;
  }
  .offset-md-2 {
    margin-left: 16.66666667%;
  }
  .offset-md-3 {
    margin-left: 25%;
  }
  .offset-md-4 {
    margin-left: 33.33333333%;
  }
  .offset-md-5 {
    margin-left: 41.66666667%;
  }
  .offset-md-6 {
    margin-left: 50%;
  }
  .offset-md-7 {
    margin-left: 58.33333333%;
  }
  .offset-md-8 {
    margin-left: 66.66666667%;
  }
  .offset-md-9 {
    margin-left: 75%;
  }
  .offset-md-10 {
    margin-left: 83.33333333%;
  }
  .offset-md-11 {
    margin-left: 91.66666667%;
  }
}
@media (min-width: 992px) {
  .col-lg {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }
  .row-cols-lg-1 > * {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .row-cols-lg-2 > * {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .row-cols-lg-3 > * {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }
  .row-cols-lg-4 > * {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .row-cols-lg-5 > * {
    flex: 0 0 20%;
    max-width: 20%;
  }
  .row-cols-lg-6 > * {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }
  .col-lg-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }
  .col-lg-1 {
    flex: 0 0 8.33333333%;
    max-width: 8.33333333%;
  }
  .col-lg-2 {
    flex: 0 0 16.66666667%;
    max-width: 16.66666667%;
  }
  .col-lg-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .col-lg-4 {
    flex: 0 0 33.33333333%;
    max-width: 33.33333333%;
  }
  .col-lg-5 {
    flex: 0 0 41.66666667%;
    max-width: 41.66666667%;
  }
  .col-lg-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .col-lg-7 {
    flex: 0 0 58.33333333%;
    max-width: 58.33333333%;
  }
  .col-lg-8 {
    flex: 0 0 66.66666667%;
    max-width: 66.66666667%;
  }
  .col-lg-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }
  .col-lg-10 {
    flex: 0 0 83.33333333%;
    max-width: 83.33333333%;
  }
  .col-lg-11 {
    flex: 0 0 91.66666667%;
    max-width: 91.66666667%;
  }
  .col-lg-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .order-lg-first {
    order: -1;
  }
  .order-lg-last {
    order: 13;
  }
  .order-lg-0 {
    order: 0;
  }
  .order-lg-1 {
    order: 1;
  }
  .order-lg-2 {
    order: 2;
  }
  .order-lg-3 {
    order: 3;
  }
  .order-lg-4 {
    order: 4;
  }
  .order-lg-5 {
    order: 5;
  }
  .order-lg-6 {
    order: 6;
  }
  .order-lg-7 {
    order: 7;
  }
  .order-lg-8 {
    order: 8;
  }
  .order-lg-9 {
    order: 9;
  }
  .order-lg-10 {
    order: 10;
  }
  .order-lg-11 {
    order: 11;
  }
  .order-lg-12 {
    order: 12;
  }
  .offset-lg-0 {
    margin-left: 0;
  }
  .offset-lg-1 {
    margin-left: 8.33333333%;
  }
  .offset-lg-2 {
    margin-left: 16.66666667%;
  }
  .offset-lg-3 {
    margin-left: 25%;
  }
  .offset-lg-4 {
    margin-left: 33.33333333%;
  }
  .offset-lg-5 {
    margin-left: 41.66666667%;
  }
  .offset-lg-6 {
    margin-left: 50%;
  }
  .offset-lg-7 {
    margin-left: 58.33333333%;
  }
  .offset-lg-8 {
    margin-left: 66.66666667%;
  }
  .offset-lg-9 {
    margin-left: 75%;
  }
  .offset-lg-10 {
    margin-left: 83.33333333%;
  }
  .offset-lg-11 {
    margin-left: 91.66666667%;
  }
}
@media (min-width: 1200px) {
  .col-xl {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }
  .row-cols-xl-1 > * {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .row-cols-xl-2 > * {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .row-cols-xl-3 > * {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }
  .row-cols-xl-4 > * {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .row-cols-xl-5 > * {
    flex: 0 0 20%;
    max-width: 20%;
  }
  .row-cols-xl-6 > * {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }
  .col-xl-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }
  .col-xl-1 {
    flex: 0 0 8.33333333%;
    max-width: 8.33333333%;
  }
  .col-xl-2 {
    flex: 0 0 16.66666667%;
    max-width: 16.66666667%;
  }
  .col-xl-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .col-xl-4 {
    flex: 0 0 33.33333333%;
    max-width: 33.33333333%;
  }
  .col-xl-5 {
    flex: 0 0 41.66666667%;
    max-width: 41.66666667%;
  }
  .col-xl-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .col-xl-7 {
    flex: 0 0 58.33333333%;
    max-width: 58.33333333%;
  }
  .col-xl-8 {
    flex: 0 0 66.66666667%;
    max-width: 66.66666667%;
  }
  .col-xl-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }
  .col-xl-10 {
    flex: 0 0 83.33333333%;
    max-width: 83.33333333%;
  }
  .col-xl-11 {
    flex: 0 0 91.66666667%;
    max-width: 91.66666667%;
  }
  .col-xl-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .order-xl-first {
    order: -1;
  }
  .order-xl-last {
    order: 13;
  }
  .order-xl-0 {
    order: 0;
  }
  .order-xl-1 {
    order: 1;
  }
  .order-xl-2 {
    order: 2;
  }
  .order-xl-3 {
    order: 3;
  }
  .order-xl-4 {
    order: 4;
  }
  .order-xl-5 {
    order: 5;
  }
  .order-xl-6 {
    order: 6;
  }
  .order-xl-7 {
    order: 7;
  }
  .order-xl-8 {
    order: 8;
  }
  .order-xl-9 {
    order: 9;
  }
  .order-xl-10 {
    order: 10;
  }
  .order-xl-11 {
    order: 11;
  }
  .order-xl-12 {
    order: 12;
  }
  .offset-xl-0 {
    margin-left: 0;
  }
  .offset-xl-1 {
    margin-left: 8.33333333%;
  }
  .offset-xl-2 {
    margin-left: 16.66666667%;
  }
  .offset-xl-3 {
    margin-left: 25%;
  }
  .offset-xl-4 {
    margin-left: 33.33333333%;
  }
  .offset-xl-5 {
    margin-left: 41.66666667%;
  }
  .offset-xl-6 {
    margin-left: 50%;
  }
  .offset-xl-7 {
    margin-left: 58.33333333%;
  }
  .offset-xl-8 {
    margin-left: 66.66666667%;
  }
  .offset-xl-9 {
    margin-left: 75%;
  }
  .offset-xl-10 {
    margin-left: 83.33333333%;
  }
  .offset-xl-11 {
    margin-left: 91.66666667%;
  }
}
.align-baseline {
  vertical-align: baseline !important;
}

.align-top {
  vertical-align: top !important;
}

.align-middle {
  vertical-align: middle !important;
}

.align-bottom {
  vertical-align: bottom !important;
}

.align-text-bottom {
  vertical-align: text-bottom !important;
}

.align-text-top {
  vertical-align: text-top !important;
}

.bg-primary {
  background-color: #007bff !important;
}

a.bg-primary:hover, a.bg-primary:focus,
button.bg-primary:hover,
button.bg-primary:focus {
  background-color: #0062cc !important;
}

.bg-secondary {
  background-color: #6c757d !important;
}

a.bg-secondary:hover, a.bg-secondary:focus,
button.bg-secondary:hover,
button.bg-secondary:focus {
  background-color: #545b62 !important;
}

.bg-success {
  background-color: #28a745 !important;
}

a.bg-success:hover, a.bg-success:focus,
button.bg-success:hover,
button.bg-success:focus {
  background-color: #1e7e34 !important;
}

.bg-info {
  background-color: #17a2b8 !important;
}

a.bg-info:hover, a.bg-info:focus,
button.bg-info:hover,
button.bg-info:focus {
  background-color: #117a8b !important;
}

.bg-warning {
  background-color: #ffc107 !important;
}

a.bg-warning:hover, a.bg-warning:focus,
button.bg-warning:hover,
button.bg-warning:focus {
  background-color: #d39e00 !important;
}

.bg-danger {
  background-color: #dc3545 !important;
}

a.bg-danger:hover, a.bg-danger:focus,
button.bg-danger:hover,
button.bg-danger:focus {
  background-color: #bd2130 !important;
}

.bg-light {
  background-color: #f8f9fa !important;
}

a.bg-light:hover, a.bg-light:focus,
button.bg-light:hover,
button.bg-light:focus {
  background-color: #dae0e5 !important;
}

.bg-dark {
  background-color: #343a40 !important;
}

a.bg-dark:hover, a.bg-dark:focus,
button.bg-dark:hover,
button.bg-dark:focus {
  background-color: #1d2124 !important;
}

.bg-white {
  background-color: #fff !important;
}

.bg-transparent {
  background-color: transparent !important;
}

.border {
  border: 1px solid #dee2e6 !important;
}

.border-top {
  border-top: 1px solid #dee2e6 !important;
}

.border-right {
  border-right: 1px solid #dee2e6 !important;
}

.border-bottom {
  border-bottom: 1px solid #dee2e6 !important;
}

.border-left {
  border-left: 1px solid #dee2e6 !important;
}

.border-0 {
  border: 0 !important;
}

.border-top-0 {
  border-top: 0 !important;
}

.border-right-0 {
  border-right: 0 !important;
}

.border-bottom-0 {
  border-bottom: 0 !important;
}

.border-left-0 {
  border-left: 0 !important;
}

.border-primary {
  border-color: #007bff !important;
}

.border-secondary {
  border-color: #6c757d !important;
}

.border-success {
  border-color: #28a745 !important;
}

.border-info {
  border-color: #17a2b8 !important;
}

.border-warning {
  border-color: #ffc107 !important;
}

.border-danger {
  border-color: #dc3545 !important;
}

.border-light {
  border-color: #f8f9fa !important;
}

.border-dark {
  border-color: #343a40 !important;
}

.border-white {
  border-color: #fff !important;
}

.rounded-sm {
  border-radius: 0.2rem !important;
}

.rounded {
  border-radius: 0.25rem !important;
}

.rounded-top {
  border-top-left-radius: 0.25rem !important;
  border-top-right-radius: 0.25rem !important;
}

.rounded-right {
  border-top-right-radius: 0.25rem !important;
  border-bottom-right-radius: 0.25rem !important;
}

.rounded-bottom {
  border-bottom-right-radius: 0.25rem !important;
  border-bottom-left-radius: 0.25rem !important;
}

.rounded-left {
  border-top-left-radius: 0.25rem !important;
  border-bottom-left-radius: 0.25rem !important;
}

.rounded-lg {
  border-radius: 0.3rem !important;
}

.rounded-circle {
  border-radius: 50% !important;
}

.rounded-pill {
  border-radius: 50rem !important;
}

.rounded-0 {
  border-radius: 0 !important;
}

.clearfix::after {
  display: block;
  clear: both;
  content: "";
}

.d-none {
  display: none !important;
}

.d-inline {
  display: inline !important;
}

.d-inline-block {
  display: inline-block !important;
}

.d-block {
  display: block !important;
}

.d-table {
  display: table !important;
}

.d-table-row {
  display: table-row !important;
}

.d-table-cell {
  display: table-cell !important;
}

.d-flex {
  display: flex !important;
}

.d-inline-flex {
  display: inline-flex !important;
}

@media (min-width: 576px) {
  .d-sm-none {
    display: none !important;
  }
  .d-sm-inline {
    display: inline !important;
  }
  .d-sm-inline-block {
    display: inline-block !important;
  }
  .d-sm-block {
    display: block !important;
  }
  .d-sm-table {
    display: table !important;
  }
  .d-sm-table-row {
    display: table-row !important;
  }
  .d-sm-table-cell {
    display: table-cell !important;
  }
  .d-sm-flex {
    display: flex !important;
  }
  .d-sm-inline-flex {
    display: inline-flex !important;
  }
}
@media (min-width: 768px) {
  .d-md-none {
    display: none !important;
  }
  .d-md-inline {
    display: inline !important;
  }
  .d-md-inline-block {
    display: inline-block !important;
  }
  .d-md-block {
    display: block !important;
  }
  .d-md-table {
    display: table !important;
  }
  .d-md-table-row {
    display: table-row !important;
  }
  .d-md-table-cell {
    display: table-cell !important;
  }
  .d-md-flex {
    display: flex !important;
  }
  .d-md-inline-flex {
    display: inline-flex !important;
  }
}
@media (min-width: 992px) {
  .d-lg-none {
    display: none !important;
  }
  .d-lg-inline {
    display: inline !important;
  }
  .d-lg-inline-block {
    display: inline-block !important;
  }
  .d-lg-block {
    display: block !important;
  }
  .d-lg-table {
    display: table !important;
  }
  .d-lg-table-row {
    display: table-row !important;
  }
  .d-lg-table-cell {
    display: table-cell !important;
  }
  .d-lg-flex {
    display: flex !important;
  }
  .d-lg-inline-flex {
    display: inline-flex !important;
  }
}
@media (min-width: 1200px) {
  .d-xl-none {
    display: none !important;
  }
  .d-xl-inline {
    display: inline !important;
  }
  .d-xl-inline-block {
    display: inline-block !important;
  }
  .d-xl-block {
    display: block !important;
  }
  .d-xl-table {
    display: table !important;
  }
  .d-xl-table-row {
    display: table-row !important;
  }
  .d-xl-table-cell {
    display: table-cell !important;
  }
  .d-xl-flex {
    display: flex !important;
  }
  .d-xl-inline-flex {
    display: inline-flex !important;
  }
}
@media print {
  .d-print-none {
    display: none !important;
  }
  .d-print-inline {
    display: inline !important;
  }
  .d-print-inline-block {
    display: inline-block !important;
  }
  .d-print-block {
    display: block !important;
  }
  .d-print-table {
    display: table !important;
  }
  .d-print-table-row {
    display: table-row !important;
  }
  .d-print-table-cell {
    display: table-cell !important;
  }
  .d-print-flex {
    display: flex !important;
  }
  .d-print-inline-flex {
    display: inline-flex !important;
  }
}
.embed-responsive {
  position: relative;
  display: block;
  width: 100%;
  padding: 0;
  overflow: hidden;
}
.embed-responsive::before {
  display: block;
  content: "";
}
.embed-responsive .embed-responsive-item,
.embed-responsive iframe,
.embed-responsive embed,
.embed-responsive object,
.embed-responsive video {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: 0;
}

.embed-responsive-21by9::before {
  padding-top: 42.85714286%;
}

.embed-responsive-16by9::before {
  padding-top: 56.25%;
}

.embed-responsive-4by3::before {
  padding-top: 75%;
}

.embed-responsive-1by1::before {
  padding-top: 100%;
}

.flex-row {
  flex-direction: row !important;
}

.flex-column {
  flex-direction: column !important;
}

.flex-row-reverse {
  flex-direction: row-reverse !important;
}

.flex-column-reverse {
  flex-direction: column-reverse !important;
}

.flex-wrap {
  flex-wrap: wrap !important;
}

.flex-nowrap {
  flex-wrap: nowrap !important;
}

.flex-wrap-reverse {
  flex-wrap: wrap-reverse !important;
}

.flex-fill {
  flex: 1 1 auto !important;
}

.flex-grow-0 {
  flex-grow: 0 !important;
}

.flex-grow-1 {
  flex-grow: 1 !important;
}

.flex-shrink-0 {
  flex-shrink: 0 !important;
}

.flex-shrink-1 {
  flex-shrink: 1 !important;
}

.justify-content-start {
  justify-content: flex-start !important;
}

.justify-content-end {
  justify-content: flex-end !important;
}

.justify-content-center {
  justify-content: center !important;
}

.justify-content-between {
  justify-content: space-between !important;
}

.justify-content-around {
  justify-content: space-around !important;
}

.align-items-start {
  align-items: flex-start !important;
}

.align-items-end {
  align-items: flex-end !important;
}

.align-items-center {
  align-items: center !important;
}

.align-items-baseline {
  align-items: baseline !important;
}

.align-items-stretch {
  align-items: stretch !important;
}

.align-content-start {
  align-content: flex-start !important;
}

.align-content-end {
  align-content: flex-end !important;
}

.align-content-center {
  align-content: center !important;
}

.align-content-between {
  align-content: space-between !important;
}

.align-content-around {
  align-content: space-around !important;
}

.align-content-stretch {
  align-content: stretch !important;
}

.align-self-auto {
  align-self: auto !important;
}

.align-self-start {
  align-self: flex-start !important;
}

.align-self-end {
  align-self: flex-end !important;
}

.align-self-center {
  align-self: center !important;
}

.align-self-baseline {
  align-self: baseline !important;
}

.align-self-stretch {
  align-self: stretch !important;
}

@media (min-width: 576px) {
  .flex-sm-row {
    flex-direction: row !important;
  }
  .flex-sm-column {
    flex-direction: column !important;
  }
  .flex-sm-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-sm-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-sm-wrap {
    flex-wrap: wrap !important;
  }
  .flex-sm-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-sm-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .flex-sm-fill {
    flex: 1 1 auto !important;
  }
  .flex-sm-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-sm-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-sm-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-sm-shrink-1 {
    flex-shrink: 1 !important;
  }
  .justify-content-sm-start {
    justify-content: flex-start !important;
  }
  .justify-content-sm-end {
    justify-content: flex-end !important;
  }
  .justify-content-sm-center {
    justify-content: center !important;
  }
  .justify-content-sm-between {
    justify-content: space-between !important;
  }
  .justify-content-sm-around {
    justify-content: space-around !important;
  }
  .align-items-sm-start {
    align-items: flex-start !important;
  }
  .align-items-sm-end {
    align-items: flex-end !important;
  }
  .align-items-sm-center {
    align-items: center !important;
  }
  .align-items-sm-baseline {
    align-items: baseline !important;
  }
  .align-items-sm-stretch {
    align-items: stretch !important;
  }
  .align-content-sm-start {
    align-content: flex-start !important;
  }
  .align-content-sm-end {
    align-content: flex-end !important;
  }
  .align-content-sm-center {
    align-content: center !important;
  }
  .align-content-sm-between {
    align-content: space-between !important;
  }
  .align-content-sm-around {
    align-content: space-around !important;
  }
  .align-content-sm-stretch {
    align-content: stretch !important;
  }
  .align-self-sm-auto {
    align-self: auto !important;
  }
  .align-self-sm-start {
    align-self: flex-start !important;
  }
  .align-self-sm-end {
    align-self: flex-end !important;
  }
  .align-self-sm-center {
    align-self: center !important;
  }
  .align-self-sm-baseline {
    align-self: baseline !important;
  }
  .align-self-sm-stretch {
    align-self: stretch !important;
  }
}
@media (min-width: 768px) {
  .flex-md-row {
    flex-direction: row !important;
  }
  .flex-md-column {
    flex-direction: column !important;
  }
  .flex-md-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-md-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-md-wrap {
    flex-wrap: wrap !important;
  }
  .flex-md-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-md-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .flex-md-fill {
    flex: 1 1 auto !important;
  }
  .flex-md-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-md-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-md-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-md-shrink-1 {
    flex-shrink: 1 !important;
  }
  .justify-content-md-start {
    justify-content: flex-start !important;
  }
  .justify-content-md-end {
    justify-content: flex-end !important;
  }
  .justify-content-md-center {
    justify-content: center !important;
  }
  .justify-content-md-between {
    justify-content: space-between !important;
  }
  .justify-content-md-around {
    justify-content: space-around !important;
  }
  .align-items-md-start {
    align-items: flex-start !important;
  }
  .align-items-md-end {
    align-items: flex-end !important;
  }
  .align-items-md-center {
    align-items: center !important;
  }
  .align-items-md-baseline {
    align-items: baseline !important;
  }
  .align-items-md-stretch {
    align-items: stretch !important;
  }
  .align-content-md-start {
    align-content: flex-start !important;
  }
  .align-content-md-end {
    align-content: flex-end !important;
  }
  .align-content-md-center {
    align-content: center !important;
  }
  .align-content-md-between {
    align-content: space-between !important;
  }
  .align-content-md-around {
    align-content: space-around !important;
  }
  .align-content-md-stretch {
    align-content: stretch !important;
  }
  .align-self-md-auto {
    align-self: auto !important;
  }
  .align-self-md-start {
    align-self: flex-start !important;
  }
  .align-self-md-end {
    align-self: flex-end !important;
  }
  .align-self-md-center {
    align-self: center !important;
  }
  .align-self-md-baseline {
    align-self: baseline !important;
  }
  .align-self-md-stretch {
    align-self: stretch !important;
  }
}
@media (min-width: 992px) {
  .flex-lg-row {
    flex-direction: row !important;
  }
  .flex-lg-column {
    flex-direction: column !important;
  }
  .flex-lg-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-lg-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-lg-wrap {
    flex-wrap: wrap !important;
  }
  .flex-lg-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-lg-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .flex-lg-fill {
    flex: 1 1 auto !important;
  }
  .flex-lg-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-lg-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-lg-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-lg-shrink-1 {
    flex-shrink: 1 !important;
  }
  .justify-content-lg-start {
    justify-content: flex-start !important;
  }
  .justify-content-lg-end {
    justify-content: flex-end !important;
  }
  .justify-content-lg-center {
    justify-content: center !important;
  }
  .justify-content-lg-between {
    justify-content: space-between !important;
  }
  .justify-content-lg-around {
    justify-content: space-around !important;
  }
  .align-items-lg-start {
    align-items: flex-start !important;
  }
  .align-items-lg-end {
    align-items: flex-end !important;
  }
  .align-items-lg-center {
    align-items: center !important;
  }
  .align-items-lg-baseline {
    align-items: baseline !important;
  }
  .align-items-lg-stretch {
    align-items: stretch !important;
  }
  .align-content-lg-start {
    align-content: flex-start !important;
  }
  .align-content-lg-end {
    align-content: flex-end !important;
  }
  .align-content-lg-center {
    align-content: center !important;
  }
  .align-content-lg-between {
    align-content: space-between !important;
  }
  .align-content-lg-around {
    align-content: space-around !important;
  }
  .align-content-lg-stretch {
    align-content: stretch !important;
  }
  .align-self-lg-auto {
    align-self: auto !important;
  }
  .align-self-lg-start {
    align-self: flex-start !important;
  }
  .align-self-lg-end {
    align-self: flex-end !important;
  }
  .align-self-lg-center {
    align-self: center !important;
  }
  .align-self-lg-baseline {
    align-self: baseline !important;
  }
  .align-self-lg-stretch {
    align-self: stretch !important;
  }
}
@media (min-width: 1200px) {
  .flex-xl-row {
    flex-direction: row !important;
  }
  .flex-xl-column {
    flex-direction: column !important;
  }
  .flex-xl-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-xl-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-xl-wrap {
    flex-wrap: wrap !important;
  }
  .flex-xl-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-xl-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .flex-xl-fill {
    flex: 1 1 auto !important;
  }
  .flex-xl-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-xl-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-xl-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-xl-shrink-1 {
    flex-shrink: 1 !important;
  }
  .justify-content-xl-start {
    justify-content: flex-start !important;
  }
  .justify-content-xl-end {
    justify-content: flex-end !important;
  }
  .justify-content-xl-center {
    justify-content: center !important;
  }
  .justify-content-xl-between {
    justify-content: space-between !important;
  }
  .justify-content-xl-around {
    justify-content: space-around !important;
  }
  .align-items-xl-start {
    align-items: flex-start !important;
  }
  .align-items-xl-end {
    align-items: flex-end !important;
  }
  .align-items-xl-center {
    align-items: center !important;
  }
  .align-items-xl-baseline {
    align-items: baseline !important;
  }
  .align-items-xl-stretch {
    align-items: stretch !important;
  }
  .align-content-xl-start {
    align-content: flex-start !important;
  }
  .align-content-xl-end {
    align-content: flex-end !important;
  }
  .align-content-xl-center {
    align-content: center !important;
  }
  .align-content-xl-between {
    align-content: space-between !important;
  }
  .align-content-xl-around {
    align-content: space-around !important;
  }
  .align-content-xl-stretch {
    align-content: stretch !important;
  }
  .align-self-xl-auto {
    align-self: auto !important;
  }
  .align-self-xl-start {
    align-self: flex-start !important;
  }
  .align-self-xl-end {
    align-self: flex-end !important;
  }
  .align-self-xl-center {
    align-self: center !important;
  }
  .align-self-xl-baseline {
    align-self: baseline !important;
  }
  .align-self-xl-stretch {
    align-self: stretch !important;
  }
}
.float-left {
  float: left !important;
}

.float-right {
  float: right !important;
}

.float-none {
  float: none !important;
}

@media (min-width: 576px) {
  .float-sm-left {
    float: left !important;
  }
  .float-sm-right {
    float: right !important;
  }
  .float-sm-none {
    float: none !important;
  }
}
@media (min-width: 768px) {
  .float-md-left {
    float: left !important;
  }
  .float-md-right {
    float: right !important;
  }
  .float-md-none {
    float: none !important;
  }
}
@media (min-width: 992px) {
  .float-lg-left {
    float: left !important;
  }
  .float-lg-right {
    float: right !important;
  }
  .float-lg-none {
    float: none !important;
  }
}
@media (min-width: 1200px) {
  .float-xl-left {
    float: left !important;
  }
  .float-xl-right {
    float: right !important;
  }
  .float-xl-none {
    float: none !important;
  }
}
.user-select-all {
  user-select: all !important;
}

.user-select-auto {
  user-select: auto !important;
}

.user-select-none {
  user-select: none !important;
}

.overflow-auto {
  overflow: auto !important;
}

.overflow-hidden {
  overflow: hidden !important;
}

.position-static {
  position: static !important;
}

.position-relative {
  position: relative !important;
}

.position-absolute {
  position: absolute !important;
}

.position-fixed {
  position: fixed !important;
}

.position-sticky {
  position: sticky !important;
}

.fixed-top {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1030;
}

.fixed-bottom {
  position: fixed;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1030;
}

@supports (position: sticky) {
  .sticky-top {
    position: sticky;
    top: 0;
    z-index: 1020;
  }
}

.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border: 0;
}

.sr-only-focusable:active, .sr-only-focusable:focus {
  position: static;
  width: auto;
  height: auto;
  overflow: visible;
  clip: auto;
  white-space: normal;
}

.shadow-sm {
  box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075) !important;
}

.shadow {
  box-shadow: 0 1px 4px 0 rgba(5, 5, 5, 0.14) !important;
}

.shadow-lg {
  box-shadow: 0 1rem 3rem rgba(0, 0, 0, 0.175) !important;
}

.shadow-none {
  box-shadow: none !important;
}

.w-25 {
  width: 25% !important;
}

.w-50 {
  width: 50% !important;
}

.w-75 {
  width: 75% !important;
}

.w-100 {
  width: 100% !important;
}

.w-auto {
  width: auto !important;
}

.h-25 {
  height: 25% !important;
}

.h-50 {
  height: 50% !important;
}

.h-75 {
  height: 75% !important;
}

.h-100 {
  height: 100% !important;
}

.h-auto {
  height: auto !important;
}

.mw-100 {
  max-width: 100% !important;
}

.mh-100 {
  max-height: 100% !important;
}

.min-vw-100 {
  min-width: 100vw !important;
}

.min-vh-100 {
  min-height: 100vh !important;
}

.vw-100 {
  width: 100vw !important;
}

.vh-100 {
  height: 100vh !important;
}

.m-0 {
  margin: 0 !important;
}

.mt-0,
.my-0 {
  margin-top: 0 !important;
}

.mr-0,
.mx-0 {
  margin-right: 0 !important;
}

.mb-0,
.my-0 {
  margin-bottom: 0 !important;
}

.ml-0,
.mx-0 {
  margin-left: 0 !important;
}

.m-1 {
  margin: 0.25rem !important;
}

.mt-1,
.my-1 {
  margin-top: 0.25rem !important;
}

.mr-1,
.mx-1 {
  margin-right: 0.25rem !important;
}

.mb-1,
.my-1 {
  margin-bottom: 0.25rem !important;
}

.ml-1,
.mx-1 {
  margin-left: 0.25rem !important;
}

.m-2 {
  margin: 0.5rem !important;
}

.mt-2,
.my-2 {
  margin-top: 0.5rem !important;
}

.mr-2,
.mx-2 {
  margin-right: 0.5rem !important;
}

.mb-2,
.my-2 {
  margin-bottom: 0.5rem !important;
}

.ml-2,
.mx-2 {
  margin-left: 0.5rem !important;
}

.m-3 {
  margin: 1rem !important;
}

.mt-3,
.my-3 {
  margin-top: 1rem !important;
}

.mr-3,
.mx-3 {
  margin-right: 1rem !important;
}

.mb-3,
.my-3 {
  margin-bottom: 1rem !important;
}

.ml-3,
.mx-3 {
  margin-left: 1rem !important;
}

.m-4 {
  margin: 1.5rem !important;
}

.mt-4,
.my-4 {
  margin-top: 1.5rem !important;
}

.mr-4,
.mx-4 {
  margin-right: 1.5rem !important;
}

.mb-4,
.my-4 {
  margin-bottom: 1.5rem !important;
}

.ml-4,
.mx-4 {
  margin-left: 1.5rem !important;
}

.m-5 {
  margin: 3rem !important;
}

.mt-5,
.my-5 {
  margin-top: 3rem !important;
}

.mr-5,
.mx-5 {
  margin-right: 3rem !important;
}

.mb-5,
.my-5 {
  margin-bottom: 3rem !important;
}

.ml-5,
.mx-5 {
  margin-left: 3rem !important;
}

.p-0 {
  padding: 0 !important;
}

.pt-0,
.py-0 {
  padding-top: 0 !important;
}

.pr-0,
.px-0 {
  padding-right: 0 !important;
}

.pb-0,
.py-0 {
  padding-bottom: 0 !important;
}

.pl-0,
.px-0 {
  padding-left: 0 !important;
}

.p-1 {
  padding: 0.25rem !important;
}

.pt-1,
.py-1 {
  padding-top: 0.25rem !important;
}

.pr-1,
.px-1 {
  padding-right: 0.25rem !important;
}

.pb-1,
.py-1 {
  padding-bottom: 0.25rem !important;
}

.pl-1,
.px-1 {
  padding-left: 0.25rem !important;
}

.p-2 {
  padding: 0.5rem !important;
}

.pt-2,
.py-2 {
  padding-top: 0.5rem !important;
}

.pr-2,
.px-2 {
  padding-right: 0.5rem !important;
}

.pb-2,
.py-2 {
  padding-bottom: 0.5rem !important;
}

.pl-2,
.px-2 {
  padding-left: 0.5rem !important;
}

.p-3 {
  padding: 1rem !important;
}

.pt-3,
.py-3 {
  padding-top: 1rem !important;
}

.pr-3,
.px-3 {
  padding-right: 1rem !important;
}

.pb-3,
.py-3 {
  padding-bottom: 1rem !important;
}

.pl-3,
.px-3 {
  padding-left: 1rem !important;
}

.p-4 {
  padding: 1.5rem !important;
}

.pt-4,
.py-4 {
  padding-top: 1.5rem !important;
}

.pr-4,
.px-4 {
  padding-right: 1.5rem !important;
}

.pb-4,
.py-4 {
  padding-bottom: 1.5rem !important;
}

.pl-4,
.px-4 {
  padding-left: 1.5rem !important;
}

.p-5 {
  padding: 3rem !important;
}

.pt-5,
.py-5 {
  padding-top: 3rem !important;
}

.pr-5,
.px-5 {
  padding-right: 3rem !important;
}

.pb-5,
.py-5 {
  padding-bottom: 3rem !important;
}

.pl-5,
.px-5 {
  padding-left: 3rem !important;
}

.m-n1 {
  margin: -0.25rem !important;
}

.mt-n1,
.my-n1 {
  margin-top: -0.25rem !important;
}

.mr-n1,
.mx-n1 {
  margin-right: -0.25rem !important;
}

.mb-n1,
.my-n1 {
  margin-bottom: -0.25rem !important;
}

.ml-n1,
.mx-n1 {
  margin-left: -0.25rem !important;
}

.m-n2 {
  margin: -0.5rem !important;
}

.mt-n2,
.my-n2 {
  margin-top: -0.5rem !important;
}

.mr-n2,
.mx-n2 {
  margin-right: -0.5rem !important;
}

.mb-n2,
.my-n2 {
  margin-bottom: -0.5rem !important;
}

.ml-n2,
.mx-n2 {
  margin-left: -0.5rem !important;
}

.m-n3 {
  margin: -1rem !important;
}

.mt-n3,
.my-n3 {
  margin-top: -1rem !important;
}

.mr-n3,
.mx-n3 {
  margin-right: -1rem !important;
}

.mb-n3,
.my-n3 {
  margin-bottom: -1rem !important;
}

.ml-n3,
.mx-n3 {
  margin-left: -1rem !important;
}

.m-n4 {
  margin: -1.5rem !important;
}

.mt-n4,
.my-n4 {
  margin-top: -1.5rem !important;
}

.mr-n4,
.mx-n4 {
  margin-right: -1.5rem !important;
}

.mb-n4,
.my-n4 {
  margin-bottom: -1.5rem !important;
}

.ml-n4,
.mx-n4 {
  margin-left: -1.5rem !important;
}

.m-n5 {
  margin: -3rem !important;
}

.mt-n5,
.my-n5 {
  margin-top: -3rem !important;
}

.mr-n5,
.mx-n5 {
  margin-right: -3rem !important;
}

.mb-n5,
.my-n5 {
  margin-bottom: -3rem !important;
}

.ml-n5,
.mx-n5 {
  margin-left: -3rem !important;
}

.m-auto {
  margin: auto !important;
}

.mt-auto,
.my-auto {
  margin-top: auto !important;
}

.mr-auto,
.mx-auto {
  margin-right: auto !important;
}

.mb-auto,
.my-auto {
  margin-bottom: auto !important;
}

.ml-auto,
.mx-auto {
  margin-left: auto !important;
}

@media (min-width: 576px) {
  .m-sm-0 {
    margin: 0 !important;
  }
  .mt-sm-0,
.my-sm-0 {
    margin-top: 0 !important;
  }
  .mr-sm-0,
.mx-sm-0 {
    margin-right: 0 !important;
  }
  .mb-sm-0,
.my-sm-0 {
    margin-bottom: 0 !important;
  }
  .ml-sm-0,
.mx-sm-0 {
    margin-left: 0 !important;
  }
  .m-sm-1 {
    margin: 0.25rem !important;
  }
  .mt-sm-1,
.my-sm-1 {
    margin-top: 0.25rem !important;
  }
  .mr-sm-1,
.mx-sm-1 {
    margin-right: 0.25rem !important;
  }
  .mb-sm-1,
.my-sm-1 {
    margin-bottom: 0.25rem !important;
  }
  .ml-sm-1,
.mx-sm-1 {
    margin-left: 0.25rem !important;
  }
  .m-sm-2 {
    margin: 0.5rem !important;
  }
  .mt-sm-2,
.my-sm-2 {
    margin-top: 0.5rem !important;
  }
  .mr-sm-2,
.mx-sm-2 {
    margin-right: 0.5rem !important;
  }
  .mb-sm-2,
.my-sm-2 {
    margin-bottom: 0.5rem !important;
  }
  .ml-sm-2,
.mx-sm-2 {
    margin-left: 0.5rem !important;
  }
  .m-sm-3 {
    margin: 1rem !important;
  }
  .mt-sm-3,
.my-sm-3 {
    margin-top: 1rem !important;
  }
  .mr-sm-3,
.mx-sm-3 {
    margin-right: 1rem !important;
  }
  .mb-sm-3,
.my-sm-3 {
    margin-bottom: 1rem !important;
  }
  .ml-sm-3,
.mx-sm-3 {
    margin-left: 1rem !important;
  }
  .m-sm-4 {
    margin: 1.5rem !important;
  }
  .mt-sm-4,
.my-sm-4 {
    margin-top: 1.5rem !important;
  }
  .mr-sm-4,
.mx-sm-4 {
    margin-right: 1.5rem !important;
  }
  .mb-sm-4,
.my-sm-4 {
    margin-bottom: 1.5rem !important;
  }
  .ml-sm-4,
.mx-sm-4 {
    margin-left: 1.5rem !important;
  }
  .m-sm-5 {
    margin: 3rem !important;
  }
  .mt-sm-5,
.my-sm-5 {
    margin-top: 3rem !important;
  }
  .mr-sm-5,
.mx-sm-5 {
    margin-right: 3rem !important;
  }
  .mb-sm-5,
.my-sm-5 {
    margin-bottom: 3rem !important;
  }
  .ml-sm-5,
.mx-sm-5 {
    margin-left: 3rem !important;
  }
  .p-sm-0 {
    padding: 0 !important;
  }
  .pt-sm-0,
.py-sm-0 {
    padding-top: 0 !important;
  }
  .pr-sm-0,
.px-sm-0 {
    padding-right: 0 !important;
  }
  .pb-sm-0,
.py-sm-0 {
    padding-bottom: 0 !important;
  }
  .pl-sm-0,
.px-sm-0 {
    padding-left: 0 !important;
  }
  .p-sm-1 {
    padding: 0.25rem !important;
  }
  .pt-sm-1,
.py-sm-1 {
    padding-top: 0.25rem !important;
  }
  .pr-sm-1,
.px-sm-1 {
    padding-right: 0.25rem !important;
  }
  .pb-sm-1,
.py-sm-1 {
    padding-bottom: 0.25rem !important;
  }
  .pl-sm-1,
.px-sm-1 {
    padding-left: 0.25rem !important;
  }
  .p-sm-2 {
    padding: 0.5rem !important;
  }
  .pt-sm-2,
.py-sm-2 {
    padding-top: 0.5rem !important;
  }
  .pr-sm-2,
.px-sm-2 {
    padding-right: 0.5rem !important;
  }
  .pb-sm-2,
.py-sm-2 {
    padding-bottom: 0.5rem !important;
  }
  .pl-sm-2,
.px-sm-2 {
    padding-left: 0.5rem !important;
  }
  .p-sm-3 {
    padding: 1rem !important;
  }
  .pt-sm-3,
.py-sm-3 {
    padding-top: 1rem !important;
  }
  .pr-sm-3,
.px-sm-3 {
    padding-right: 1rem !important;
  }
  .pb-sm-3,
.py-sm-3 {
    padding-bottom: 1rem !important;
  }
  .pl-sm-3,
.px-sm-3 {
    padding-left: 1rem !important;
  }
  .p-sm-4 {
    padding: 1.5rem !important;
  }
  .pt-sm-4,
.py-sm-4 {
    padding-top: 1.5rem !important;
  }
  .pr-sm-4,
.px-sm-4 {
    padding-right: 1.5rem !important;
  }
  .pb-sm-4,
.py-sm-4 {
    padding-bottom: 1.5rem !important;
  }
  .pl-sm-4,
.px-sm-4 {
    padding-left: 1.5rem !important;
  }
  .p-sm-5 {
    padding: 3rem !important;
  }
  .pt-sm-5,
.py-sm-5 {
    padding-top: 3rem !important;
  }
  .pr-sm-5,
.px-sm-5 {
    padding-right: 3rem !important;
  }
  .pb-sm-5,
.py-sm-5 {
    padding-bottom: 3rem !important;
  }
  .pl-sm-5,
.px-sm-5 {
    padding-left: 3rem !important;
  }
  .m-sm-n1 {
    margin: -0.25rem !important;
  }
  .mt-sm-n1,
.my-sm-n1 {
    margin-top: -0.25rem !important;
  }
  .mr-sm-n1,
.mx-sm-n1 {
    margin-right: -0.25rem !important;
  }
  .mb-sm-n1,
.my-sm-n1 {
    margin-bottom: -0.25rem !important;
  }
  .ml-sm-n1,
.mx-sm-n1 {
    margin-left: -0.25rem !important;
  }
  .m-sm-n2 {
    margin: -0.5rem !important;
  }
  .mt-sm-n2,
.my-sm-n2 {
    margin-top: -0.5rem !important;
  }
  .mr-sm-n2,
.mx-sm-n2 {
    margin-right: -0.5rem !important;
  }
  .mb-sm-n2,
.my-sm-n2 {
    margin-bottom: -0.5rem !important;
  }
  .ml-sm-n2,
.mx-sm-n2 {
    margin-left: -0.5rem !important;
  }
  .m-sm-n3 {
    margin: -1rem !important;
  }
  .mt-sm-n3,
.my-sm-n3 {
    margin-top: -1rem !important;
  }
  .mr-sm-n3,
.mx-sm-n3 {
    margin-right: -1rem !important;
  }
  .mb-sm-n3,
.my-sm-n3 {
    margin-bottom: -1rem !important;
  }
  .ml-sm-n3,
.mx-sm-n3 {
    margin-left: -1rem !important;
  }
  .m-sm-n4 {
    margin: -1.5rem !important;
  }
  .mt-sm-n4,
.my-sm-n4 {
    margin-top: -1.5rem !important;
  }
  .mr-sm-n4,
.mx-sm-n4 {
    margin-right: -1.5rem !important;
  }
  .mb-sm-n4,
.my-sm-n4 {
    margin-bottom: -1.5rem !important;
  }
  .ml-sm-n4,
.mx-sm-n4 {
    margin-left: -1.5rem !important;
  }
  .m-sm-n5 {
    margin: -3rem !important;
  }
  .mt-sm-n5,
.my-sm-n5 {
    margin-top: -3rem !important;
  }
  .mr-sm-n5,
.mx-sm-n5 {
    margin-right: -3rem !important;
  }
  .mb-sm-n5,
.my-sm-n5 {
    margin-bottom: -3rem !important;
  }
  .ml-sm-n5,
.mx-sm-n5 {
    margin-left: -3rem !important;
  }
  .m-sm-auto {
    margin: auto !important;
  }
  .mt-sm-auto,
.my-sm-auto {
    margin-top: auto !important;
  }
  .mr-sm-auto,
.mx-sm-auto {
    margin-right: auto !important;
  }
  .mb-sm-auto,
.my-sm-auto {
    margin-bottom: auto !important;
  }
  .ml-sm-auto,
.mx-sm-auto {
    margin-left: auto !important;
  }
}
@media (min-width: 768px) {
  .m-md-0 {
    margin: 0 !important;
  }
  .mt-md-0,
.my-md-0 {
    margin-top: 0 !important;
  }
  .mr-md-0,
.mx-md-0 {
    margin-right: 0 !important;
  }
  .mb-md-0,
.my-md-0 {
    margin-bottom: 0 !important;
  }
  .ml-md-0,
.mx-md-0 {
    margin-left: 0 !important;
  }
  .m-md-1 {
    margin: 0.25rem !important;
  }
  .mt-md-1,
.my-md-1 {
    margin-top: 0.25rem !important;
  }
  .mr-md-1,
.mx-md-1 {
    margin-right: 0.25rem !important;
  }
  .mb-md-1,
.my-md-1 {
    margin-bottom: 0.25rem !important;
  }
  .ml-md-1,
.mx-md-1 {
    margin-left: 0.25rem !important;
  }
  .m-md-2 {
    margin: 0.5rem !important;
  }
  .mt-md-2,
.my-md-2 {
    margin-top: 0.5rem !important;
  }
  .mr-md-2,
.mx-md-2 {
    margin-right: 0.5rem !important;
  }
  .mb-md-2,
.my-md-2 {
    margin-bottom: 0.5rem !important;
  }
  .ml-md-2,
.mx-md-2 {
    margin-left: 0.5rem !important;
  }
  .m-md-3 {
    margin: 1rem !important;
  }
  .mt-md-3,
.my-md-3 {
    margin-top: 1rem !important;
  }
  .mr-md-3,
.mx-md-3 {
    margin-right: 1rem !important;
  }
  .mb-md-3,
.my-md-3 {
    margin-bottom: 1rem !important;
  }
  .ml-md-3,
.mx-md-3 {
    margin-left: 1rem !important;
  }
  .m-md-4 {
    margin: 1.5rem !important;
  }
  .mt-md-4,
.my-md-4 {
    margin-top: 1.5rem !important;
  }
  .mr-md-4,
.mx-md-4 {
    margin-right: 1.5rem !important;
  }
  .mb-md-4,
.my-md-4 {
    margin-bottom: 1.5rem !important;
  }
  .ml-md-4,
.mx-md-4 {
    margin-left: 1.5rem !important;
  }
  .m-md-5 {
    margin: 3rem !important;
  }
  .mt-md-5,
.my-md-5 {
    margin-top: 3rem !important;
  }
  .mr-md-5,
.mx-md-5 {
    margin-right: 3rem !important;
  }
  .mb-md-5,
.my-md-5 {
    margin-bottom: 3rem !important;
  }
  .ml-md-5,
.mx-md-5 {
    margin-left: 3rem !important;
  }
  .p-md-0 {
    padding: 0 !important;
  }
  .pt-md-0,
.py-md-0 {
    padding-top: 0 !important;
  }
  .pr-md-0,
.px-md-0 {
    padding-right: 0 !important;
  }
  .pb-md-0,
.py-md-0 {
    padding-bottom: 0 !important;
  }
  .pl-md-0,
.px-md-0 {
    padding-left: 0 !important;
  }
  .p-md-1 {
    padding: 0.25rem !important;
  }
  .pt-md-1,
.py-md-1 {
    padding-top: 0.25rem !important;
  }
  .pr-md-1,
.px-md-1 {
    padding-right: 0.25rem !important;
  }
  .pb-md-1,
.py-md-1 {
    padding-bottom: 0.25rem !important;
  }
  .pl-md-1,
.px-md-1 {
    padding-left: 0.25rem !important;
  }
  .p-md-2 {
    padding: 0.5rem !important;
  }
  .pt-md-2,
.py-md-2 {
    padding-top: 0.5rem !important;
  }
  .pr-md-2,
.px-md-2 {
    padding-right: 0.5rem !important;
  }
  .pb-md-2,
.py-md-2 {
    padding-bottom: 0.5rem !important;
  }
  .pl-md-2,
.px-md-2 {
    padding-left: 0.5rem !important;
  }
  .p-md-3 {
    padding: 1rem !important;
  }
  .pt-md-3,
.py-md-3 {
    padding-top: 1rem !important;
  }
  .pr-md-3,
.px-md-3 {
    padding-right: 1rem !important;
  }
  .pb-md-3,
.py-md-3 {
    padding-bottom: 1rem !important;
  }
  .pl-md-3,
.px-md-3 {
    padding-left: 1rem !important;
  }
  .p-md-4 {
    padding: 1.5rem !important;
  }
  .pt-md-4,
.py-md-4 {
    padding-top: 1.5rem !important;
  }
  .pr-md-4,
.px-md-4 {
    padding-right: 1.5rem !important;
  }
  .pb-md-4,
.py-md-4 {
    padding-bottom: 1.5rem !important;
  }
  .pl-md-4,
.px-md-4 {
    padding-left: 1.5rem !important;
  }
  .p-md-5 {
    padding: 3rem !important;
  }
  .pt-md-5,
.py-md-5 {
    padding-top: 3rem !important;
  }
  .pr-md-5,
.px-md-5 {
    padding-right: 3rem !important;
  }
  .pb-md-5,
.py-md-5 {
    padding-bottom: 3rem !important;
  }
  .pl-md-5,
.px-md-5 {
    padding-left: 3rem !important;
  }
  .m-md-n1 {
    margin: -0.25rem !important;
  }
  .mt-md-n1,
.my-md-n1 {
    margin-top: -0.25rem !important;
  }
  .mr-md-n1,
.mx-md-n1 {
    margin-right: -0.25rem !important;
  }
  .mb-md-n1,
.my-md-n1 {
    margin-bottom: -0.25rem !important;
  }
  .ml-md-n1,
.mx-md-n1 {
    margin-left: -0.25rem !important;
  }
  .m-md-n2 {
    margin: -0.5rem !important;
  }
  .mt-md-n2,
.my-md-n2 {
    margin-top: -0.5rem !important;
  }
  .mr-md-n2,
.mx-md-n2 {
    margin-right: -0.5rem !important;
  }
  .mb-md-n2,
.my-md-n2 {
    margin-bottom: -0.5rem !important;
  }
  .ml-md-n2,
.mx-md-n2 {
    margin-left: -0.5rem !important;
  }
  .m-md-n3 {
    margin: -1rem !important;
  }
  .mt-md-n3,
.my-md-n3 {
    margin-top: -1rem !important;
  }
  .mr-md-n3,
.mx-md-n3 {
    margin-right: -1rem !important;
  }
  .mb-md-n3,
.my-md-n3 {
    margin-bottom: -1rem !important;
  }
  .ml-md-n3,
.mx-md-n3 {
    margin-left: -1rem !important;
  }
  .m-md-n4 {
    margin: -1.5rem !important;
  }
  .mt-md-n4,
.my-md-n4 {
    margin-top: -1.5rem !important;
  }
  .mr-md-n4,
.mx-md-n4 {
    margin-right: -1.5rem !important;
  }
  .mb-md-n4,
.my-md-n4 {
    margin-bottom: -1.5rem !important;
  }
  .ml-md-n4,
.mx-md-n4 {
    margin-left: -1.5rem !important;
  }
  .m-md-n5 {
    margin: -3rem !important;
  }
  .mt-md-n5,
.my-md-n5 {
    margin-top: -3rem !important;
  }
  .mr-md-n5,
.mx-md-n5 {
    margin-right: -3rem !important;
  }
  .mb-md-n5,
.my-md-n5 {
    margin-bottom: -3rem !important;
  }
  .ml-md-n5,
.mx-md-n5 {
    margin-left: -3rem !important;
  }
  .m-md-auto {
    margin: auto !important;
  }
  .mt-md-auto,
.my-md-auto {
    margin-top: auto !important;
  }
  .mr-md-auto,
.mx-md-auto {
    margin-right: auto !important;
  }
  .mb-md-auto,
.my-md-auto {
    margin-bottom: auto !important;
  }
  .ml-md-auto,
.mx-md-auto {
    margin-left: auto !important;
  }
}
@media (min-width: 992px) {
  .m-lg-0 {
    margin: 0 !important;
  }
  .mt-lg-0,
.my-lg-0 {
    margin-top: 0 !important;
  }
  .mr-lg-0,
.mx-lg-0 {
    margin-right: 0 !important;
  }
  .mb-lg-0,
.my-lg-0 {
    margin-bottom: 0 !important;
  }
  .ml-lg-0,
.mx-lg-0 {
    margin-left: 0 !important;
  }
  .m-lg-1 {
    margin: 0.25rem !important;
  }
  .mt-lg-1,
.my-lg-1 {
    margin-top: 0.25rem !important;
  }
  .mr-lg-1,
.mx-lg-1 {
    margin-right: 0.25rem !important;
  }
  .mb-lg-1,
.my-lg-1 {
    margin-bottom: 0.25rem !important;
  }
  .ml-lg-1,
.mx-lg-1 {
    margin-left: 0.25rem !important;
  }
  .m-lg-2 {
    margin: 0.5rem !important;
  }
  .mt-lg-2,
.my-lg-2 {
    margin-top: 0.5rem !important;
  }
  .mr-lg-2,
.mx-lg-2 {
    margin-right: 0.5rem !important;
  }
  .mb-lg-2,
.my-lg-2 {
    margin-bottom: 0.5rem !important;
  }
  .ml-lg-2,
.mx-lg-2 {
    margin-left: 0.5rem !important;
  }
  .m-lg-3 {
    margin: 1rem !important;
  }
  .mt-lg-3,
.my-lg-3 {
    margin-top: 1rem !important;
  }
  .mr-lg-3,
.mx-lg-3 {
    margin-right: 1rem !important;
  }
  .mb-lg-3,
.my-lg-3 {
    margin-bottom: 1rem !important;
  }
  .ml-lg-3,
.mx-lg-3 {
    margin-left: 1rem !important;
  }
  .m-lg-4 {
    margin: 1.5rem !important;
  }
  .mt-lg-4,
.my-lg-4 {
    margin-top: 1.5rem !important;
  }
  .mr-lg-4,
.mx-lg-4 {
    margin-right: 1.5rem !important;
  }
  .mb-lg-4,
.my-lg-4 {
    margin-bottom: 1.5rem !important;
  }
  .ml-lg-4,
.mx-lg-4 {
    margin-left: 1.5rem !important;
  }
  .m-lg-5 {
    margin: 3rem !important;
  }
  .mt-lg-5,
.my-lg-5 {
    margin-top: 3rem !important;
  }
  .mr-lg-5,
.mx-lg-5 {
    margin-right: 3rem !important;
  }
  .mb-lg-5,
.my-lg-5 {
    margin-bottom: 3rem !important;
  }
  .ml-lg-5,
.mx-lg-5 {
    margin-left: 3rem !important;
  }
  .p-lg-0 {
    padding: 0 !important;
  }
  .pt-lg-0,
.py-lg-0 {
    padding-top: 0 !important;
  }
  .pr-lg-0,
.px-lg-0 {
    padding-right: 0 !important;
  }
  .pb-lg-0,
.py-lg-0 {
    padding-bottom: 0 !important;
  }
  .pl-lg-0,
.px-lg-0 {
    padding-left: 0 !important;
  }
  .p-lg-1 {
    padding: 0.25rem !important;
  }
  .pt-lg-1,
.py-lg-1 {
    padding-top: 0.25rem !important;
  }
  .pr-lg-1,
.px-lg-1 {
    padding-right: 0.25rem !important;
  }
  .pb-lg-1,
.py-lg-1 {
    padding-bottom: 0.25rem !important;
  }
  .pl-lg-1,
.px-lg-1 {
    padding-left: 0.25rem !important;
  }
  .p-lg-2 {
    padding: 0.5rem !important;
  }
  .pt-lg-2,
.py-lg-2 {
    padding-top: 0.5rem !important;
  }
  .pr-lg-2,
.px-lg-2 {
    padding-right: 0.5rem !important;
  }
  .pb-lg-2,
.py-lg-2 {
    padding-bottom: 0.5rem !important;
  }
  .pl-lg-2,
.px-lg-2 {
    padding-left: 0.5rem !important;
  }
  .p-lg-3 {
    padding: 1rem !important;
  }
  .pt-lg-3,
.py-lg-3 {
    padding-top: 1rem !important;
  }
  .pr-lg-3,
.px-lg-3 {
    padding-right: 1rem !important;
  }
  .pb-lg-3,
.py-lg-3 {
    padding-bottom: 1rem !important;
  }
  .pl-lg-3,
.px-lg-3 {
    padding-left: 1rem !important;
  }
  .p-lg-4 {
    padding: 1.5rem !important;
  }
  .pt-lg-4,
.py-lg-4 {
    padding-top: 1.5rem !important;
  }
  .pr-lg-4,
.px-lg-4 {
    padding-right: 1.5rem !important;
  }
  .pb-lg-4,
.py-lg-4 {
    padding-bottom: 1.5rem !important;
  }
  .pl-lg-4,
.px-lg-4 {
    padding-left: 1.5rem !important;
  }
  .p-lg-5 {
    padding: 3rem !important;
  }
  .pt-lg-5,
.py-lg-5 {
    padding-top: 3rem !important;
  }
  .pr-lg-5,
.px-lg-5 {
    padding-right: 3rem !important;
  }
  .pb-lg-5,
.py-lg-5 {
    padding-bottom: 3rem !important;
  }
  .pl-lg-5,
.px-lg-5 {
    padding-left: 3rem !important;
  }
  .m-lg-n1 {
    margin: -0.25rem !important;
  }
  .mt-lg-n1,
.my-lg-n1 {
    margin-top: -0.25rem !important;
  }
  .mr-lg-n1,
.mx-lg-n1 {
    margin-right: -0.25rem !important;
  }
  .mb-lg-n1,
.my-lg-n1 {
    margin-bottom: -0.25rem !important;
  }
  .ml-lg-n1,
.mx-lg-n1 {
    margin-left: -0.25rem !important;
  }
  .m-lg-n2 {
    margin: -0.5rem !important;
  }
  .mt-lg-n2,
.my-lg-n2 {
    margin-top: -0.5rem !important;
  }
  .mr-lg-n2,
.mx-lg-n2 {
    margin-right: -0.5rem !important;
  }
  .mb-lg-n2,
.my-lg-n2 {
    margin-bottom: -0.5rem !important;
  }
  .ml-lg-n2,
.mx-lg-n2 {
    margin-left: -0.5rem !important;
  }
  .m-lg-n3 {
    margin: -1rem !important;
  }
  .mt-lg-n3,
.my-lg-n3 {
    margin-top: -1rem !important;
  }
  .mr-lg-n3,
.mx-lg-n3 {
    margin-right: -1rem !important;
  }
  .mb-lg-n3,
.my-lg-n3 {
    margin-bottom: -1rem !important;
  }
  .ml-lg-n3,
.mx-lg-n3 {
    margin-left: -1rem !important;
  }
  .m-lg-n4 {
    margin: -1.5rem !important;
  }
  .mt-lg-n4,
.my-lg-n4 {
    margin-top: -1.5rem !important;
  }
  .mr-lg-n4,
.mx-lg-n4 {
    margin-right: -1.5rem !important;
  }
  .mb-lg-n4,
.my-lg-n4 {
    margin-bottom: -1.5rem !important;
  }
  .ml-lg-n4,
.mx-lg-n4 {
    margin-left: -1.5rem !important;
  }
  .m-lg-n5 {
    margin: -3rem !important;
  }
  .mt-lg-n5,
.my-lg-n5 {
    margin-top: -3rem !important;
  }
  .mr-lg-n5,
.mx-lg-n5 {
    margin-right: -3rem !important;
  }
  .mb-lg-n5,
.my-lg-n5 {
    margin-bottom: -3rem !important;
  }
  .ml-lg-n5,
.mx-lg-n5 {
    margin-left: -3rem !important;
  }
  .m-lg-auto {
    margin: auto !important;
  }
  .mt-lg-auto,
.my-lg-auto {
    margin-top: auto !important;
  }
  .mr-lg-auto,
.mx-lg-auto {
    margin-right: auto !important;
  }
  .mb-lg-auto,
.my-lg-auto {
    margin-bottom: auto !important;
  }
  .ml-lg-auto,
.mx-lg-auto {
    margin-left: auto !important;
  }
}
@media (min-width: 1200px) {
  .m-xl-0 {
    margin: 0 !important;
  }
  .mt-xl-0,
.my-xl-0 {
    margin-top: 0 !important;
  }
  .mr-xl-0,
.mx-xl-0 {
    margin-right: 0 !important;
  }
  .mb-xl-0,
.my-xl-0 {
    margin-bottom: 0 !important;
  }
  .ml-xl-0,
.mx-xl-0 {
    margin-left: 0 !important;
  }
  .m-xl-1 {
    margin: 0.25rem !important;
  }
  .mt-xl-1,
.my-xl-1 {
    margin-top: 0.25rem !important;
  }
  .mr-xl-1,
.mx-xl-1 {
    margin-right: 0.25rem !important;
  }
  .mb-xl-1,
.my-xl-1 {
    margin-bottom: 0.25rem !important;
  }
  .ml-xl-1,
.mx-xl-1 {
    margin-left: 0.25rem !important;
  }
  .m-xl-2 {
    margin: 0.5rem !important;
  }
  .mt-xl-2,
.my-xl-2 {
    margin-top: 0.5rem !important;
  }
  .mr-xl-2,
.mx-xl-2 {
    margin-right: 0.5rem !important;
  }
  .mb-xl-2,
.my-xl-2 {
    margin-bottom: 0.5rem !important;
  }
  .ml-xl-2,
.mx-xl-2 {
    margin-left: 0.5rem !important;
  }
  .m-xl-3 {
    margin: 1rem !important;
  }
  .mt-xl-3,
.my-xl-3 {
    margin-top: 1rem !important;
  }
  .mr-xl-3,
.mx-xl-3 {
    margin-right: 1rem !important;
  }
  .mb-xl-3,
.my-xl-3 {
    margin-bottom: 1rem !important;
  }
  .ml-xl-3,
.mx-xl-3 {
    margin-left: 1rem !important;
  }
  .m-xl-4 {
    margin: 1.5rem !important;
  }
  .mt-xl-4,
.my-xl-4 {
    margin-top: 1.5rem !important;
  }
  .mr-xl-4,
.mx-xl-4 {
    margin-right: 1.5rem !important;
  }
  .mb-xl-4,
.my-xl-4 {
    margin-bottom: 1.5rem !important;
  }
  .ml-xl-4,
.mx-xl-4 {
    margin-left: 1.5rem !important;
  }
  .m-xl-5 {
    margin: 3rem !important;
  }
  .mt-xl-5,
.my-xl-5 {
    margin-top: 3rem !important;
  }
  .mr-xl-5,
.mx-xl-5 {
    margin-right: 3rem !important;
  }
  .mb-xl-5,
.my-xl-5 {
    margin-bottom: 3rem !important;
  }
  .ml-xl-5,
.mx-xl-5 {
    margin-left: 3rem !important;
  }
  .p-xl-0 {
    padding: 0 !important;
  }
  .pt-xl-0,
.py-xl-0 {
    padding-top: 0 !important;
  }
  .pr-xl-0,
.px-xl-0 {
    padding-right: 0 !important;
  }
  .pb-xl-0,
.py-xl-0 {
    padding-bottom: 0 !important;
  }
  .pl-xl-0,
.px-xl-0 {
    padding-left: 0 !important;
  }
  .p-xl-1 {
    padding: 0.25rem !important;
  }
  .pt-xl-1,
.py-xl-1 {
    padding-top: 0.25rem !important;
  }
  .pr-xl-1,
.px-xl-1 {
    padding-right: 0.25rem !important;
  }
  .pb-xl-1,
.py-xl-1 {
    padding-bottom: 0.25rem !important;
  }
  .pl-xl-1,
.px-xl-1 {
    padding-left: 0.25rem !important;
  }
  .p-xl-2 {
    padding: 0.5rem !important;
  }
  .pt-xl-2,
.py-xl-2 {
    padding-top: 0.5rem !important;
  }
  .pr-xl-2,
.px-xl-2 {
    padding-right: 0.5rem !important;
  }
  .pb-xl-2,
.py-xl-2 {
    padding-bottom: 0.5rem !important;
  }
  .pl-xl-2,
.px-xl-2 {
    padding-left: 0.5rem !important;
  }
  .p-xl-3 {
    padding: 1rem !important;
  }
  .pt-xl-3,
.py-xl-3 {
    padding-top: 1rem !important;
  }
  .pr-xl-3,
.px-xl-3 {
    padding-right: 1rem !important;
  }
  .pb-xl-3,
.py-xl-3 {
    padding-bottom: 1rem !important;
  }
  .pl-xl-3,
.px-xl-3 {
    padding-left: 1rem !important;
  }
  .p-xl-4 {
    padding: 1.5rem !important;
  }
  .pt-xl-4,
.py-xl-4 {
    padding-top: 1.5rem !important;
  }
  .pr-xl-4,
.px-xl-4 {
    padding-right: 1.5rem !important;
  }
  .pb-xl-4,
.py-xl-4 {
    padding-bottom: 1.5rem !important;
  }
  .pl-xl-4,
.px-xl-4 {
    padding-left: 1.5rem !important;
  }
  .p-xl-5 {
    padding: 3rem !important;
  }
  .pt-xl-5,
.py-xl-5 {
    padding-top: 3rem !important;
  }
  .pr-xl-5,
.px-xl-5 {
    padding-right: 3rem !important;
  }
  .pb-xl-5,
.py-xl-5 {
    padding-bottom: 3rem !important;
  }
  .pl-xl-5,
.px-xl-5 {
    padding-left: 3rem !important;
  }
  .m-xl-n1 {
    margin: -0.25rem !important;
  }
  .mt-xl-n1,
.my-xl-n1 {
    margin-top: -0.25rem !important;
  }
  .mr-xl-n1,
.mx-xl-n1 {
    margin-right: -0.25rem !important;
  }
  .mb-xl-n1,
.my-xl-n1 {
    margin-bottom: -0.25rem !important;
  }
  .ml-xl-n1,
.mx-xl-n1 {
    margin-left: -0.25rem !important;
  }
  .m-xl-n2 {
    margin: -0.5rem !important;
  }
  .mt-xl-n2,
.my-xl-n2 {
    margin-top: -0.5rem !important;
  }
  .mr-xl-n2,
.mx-xl-n2 {
    margin-right: -0.5rem !important;
  }
  .mb-xl-n2,
.my-xl-n2 {
    margin-bottom: -0.5rem !important;
  }
  .ml-xl-n2,
.mx-xl-n2 {
    margin-left: -0.5rem !important;
  }
  .m-xl-n3 {
    margin: -1rem !important;
  }
  .mt-xl-n3,
.my-xl-n3 {
    margin-top: -1rem !important;
  }
  .mr-xl-n3,
.mx-xl-n3 {
    margin-right: -1rem !important;
  }
  .mb-xl-n3,
.my-xl-n3 {
    margin-bottom: -1rem !important;
  }
  .ml-xl-n3,
.mx-xl-n3 {
    margin-left: -1rem !important;
  }
  .m-xl-n4 {
    margin: -1.5rem !important;
  }
  .mt-xl-n4,
.my-xl-n4 {
    margin-top: -1.5rem !important;
  }
  .mr-xl-n4,
.mx-xl-n4 {
    margin-right: -1.5rem !important;
  }
  .mb-xl-n4,
.my-xl-n4 {
    margin-bottom: -1.5rem !important;
  }
  .ml-xl-n4,
.mx-xl-n4 {
    margin-left: -1.5rem !important;
  }
  .m-xl-n5 {
    margin: -3rem !important;
  }
  .mt-xl-n5,
.my-xl-n5 {
    margin-top: -3rem !important;
  }
  .mr-xl-n5,
.mx-xl-n5 {
    margin-right: -3rem !important;
  }
  .mb-xl-n5,
.my-xl-n5 {
    margin-bottom: -3rem !important;
  }
  .ml-xl-n5,
.mx-xl-n5 {
    margin-left: -3rem !important;
  }
  .m-xl-auto {
    margin: auto !important;
  }
  .mt-xl-auto,
.my-xl-auto {
    margin-top: auto !important;
  }
  .mr-xl-auto,
.mx-xl-auto {
    margin-right: auto !important;
  }
  .mb-xl-auto,
.my-xl-auto {
    margin-bottom: auto !important;
  }
  .ml-xl-auto,
.mx-xl-auto {
    margin-left: auto !important;
  }
}
.stretched-link::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
  pointer-events: auto;
  content: "";
  background-color: rgba(0, 0, 0, 0);
}

.text-monospace {
  font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace !important;
}

.text-justify {
  text-align: justify !important;
}

.text-wrap {
  white-space: normal !important;
}

.text-nowrap {
  white-space: nowrap !important;
}

.text-truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.text-left {
  text-align: left !important;
}

.text-right {
  text-align: right !important;
}

.text-center {
  text-align: center !important;
}

@media (min-width: 576px) {
  .text-sm-left {
    text-align: left !important;
  }
  .text-sm-right {
    text-align: right !important;
  }
  .text-sm-center {
    text-align: center !important;
  }
}
@media (min-width: 768px) {
  .text-md-left {
    text-align: left !important;
  }
  .text-md-right {
    text-align: right !important;
  }
  .text-md-center {
    text-align: center !important;
  }
}
@media (min-width: 992px) {
  .text-lg-left {
    text-align: left !important;
  }
  .text-lg-right {
    text-align: right !important;
  }
  .text-lg-center {
    text-align: center !important;
  }
}
@media (min-width: 1200px) {
  .text-xl-left {
    text-align: left !important;
  }
  .text-xl-right {
    text-align: right !important;
  }
  .text-xl-center {
    text-align: center !important;
  }
}
.text-lowercase {
  text-transform: lowercase !important;
}

.text-uppercase {
  text-transform: uppercase !important;
}

.text-capitalize {
  text-transform: capitalize !important;
}

.font-weight-light {
  font-weight: 300 !important;
}

.font-weight-lighter {
  font-weight: lighter !important;
}

.font-weight-normal {
  font-weight: 400 !important;
}

.font-weight-bold {
  font-weight: 700 !important;
}

.font-weight-bolder {
  font-weight: bolder !important;
}

.font-italic {
  font-style: italic !important;
}

.text-white {
  color: #fff !important;
}

.text-primary {
  color: #007bff !important;
}

a.text-primary:hover, a.text-primary:focus {
  color: #0056b3 !important;
}

.text-secondary {
  color: #6c757d !important;
}

a.text-secondary:hover, a.text-secondary:focus {
  color: #494f54 !important;
}

.text-success {
  color: #28a745 !important;
}

a.text-success:hover, a.text-success:focus {
  color: #19692c !important;
}

.text-info {
  color: #17a2b8 !important;
}

a.text-info:hover, a.text-info:focus {
  color: #0f6674 !important;
}

.text-warning {
  color: #ffc107 !important;
}

a.text-warning:hover, a.text-warning:focus {
  color: #ba8b00 !important;
}

.text-danger {
  color: #dc3545 !important;
}

a.text-danger:hover, a.text-danger:focus {
  color: #a71d2a !important;
}

.text-light {
  color: #f8f9fa !important;
}

a.text-light:hover, a.text-light:focus {
  color: #cbd3da !important;
}

.text-dark {
  color: #343a40 !important;
}

a.text-dark:hover, a.text-dark:focus {
  color: #121416 !important;
}

.text-body {
  color: #212529 !important;
}

.text-muted {
  color: #6c757d !important;
}

.text-black-50 {
  color: rgba(0, 0, 0, 0.5) !important;
}

.text-white-50 {
  color: rgba(255, 255, 255, 0.5) !important;
}

.text-hide {
  font: 0/0 a;
  color: transparent;
  text-shadow: none;
  background-color: transparent;
  border: 0;
}

.text-decoration-none {
  text-decoration: none !important;
}

.text-break {
  word-break: break-word !important;
  word-wrap: break-word !important;
}

.text-reset {
  color: inherit !important;
}

.visible {
  visibility: visible !important;
}

.invisible {
  visibility: hidden !important;
}

@media only screen and (min-width: 1200px) {
  .container-fluid, .container-sm, .container-md, .container-lg, .container-xl {
    max-width: calc(100vw - 40px);
  }
}
@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
.fade-in {
  animation: fadeIn 200ms 1;
}

@keyframes fadeInHalf {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 0.5;
  }
}
.fade-in-half {
  animation: fadeInHalf 200ms 1;
}

@keyframes fadeInFull {
  0% {
    opacity: 0.5;
  }
  100% {
    opacity: 1;
  }
}
.fade-in-full {
  animation: fadeInFull 200ms 1;
}

.animation-container {
  height: 40px;
  overflow: hidden;
}

.animation-container.animation-container-small {
  height: 12px;
}

.animation-container.animation-container-right .skeleton-line-2 {
  left: 0;
  right: 150px;
}

@keyframes blockTextShine {
  0% {
    transform: translateX(-468px);
  }
  100% {
    transform: translateX(468px);
  }
}
.slide-down-enter-active {
  transition: transform 0.2s;
}

.slide-down-enter, .slide-down-leave-to {
  transform: translateY(-30%);
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.transform-origin-center {
  -webkit-transform-origin: 50% 50%;
  transform-origin: 50% 50%;
}

.animate-n-spin {
  -webkit-animation-name: spin;
  animation-name: spin;
}

.animate-c-infinite {
  -webkit-animation-iteration-count: infinite;
  animation-iteration-count: infinite;
}

.animate-t-linear {
  -webkit-animation-timing-function: linear;
  animation-timing-function: linear;
}

.animate-d-1 {
  -webkit-animation-duration: 1s;
  animation-duration: 1s;
}

.animate-d-2 {
  -webkit-animation-duration: 2s;
  animation-duration: 2s;
}

.gl-spinner {
  -webkit-animation-name: spin;
  animation-name: spin;
  -webkit-animation-iteration-count: infinite;
  animation-iteration-count: infinite;
  -webkit-animation-timing-function: linear;
  animation-timing-function: linear;
  -webkit-animation-duration: 1s;
  animation-duration: 1s;
  transform-origin: 50% 50%;
}

@keyframes slide-in-fwd-bottom {
  0% {
    transform: translateZ(-1400px) translateY(800px);
    opacity: 0;
  }
  100% {
    transform: translateZ(0) translateY(0);
    opacity: 1;
  }
}
.slide-in-fwd-bottom-enter-active {
  animation: slide-in-fwd-bottom 0.4s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
}

.slide-in-fwd-bottom-leave-active {
  animation: slide-in-fwd-bottom 0.4s cubic-bezier(0.25, 0.46, 0.45, 0.94) both reverse;
}

.fade-enter-active, .fade-leave-active, .fade-in-enter-active, .fade-out-leave-active {
  transition: opacity 0.3s linear;
}

.fade-enter, .fade-in-enter, .fade-out-leave-to, .fade-leave-to {
  opacity: 0;
}

.preloader {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1001;
  background-color: #fff;
  text-align: center;
  font-size: 0;
}
.preloader__loader {
  border-radius: 50px;
  line-height: 50px;
  text-align: center;
  width: 50px;
  height: 50px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-transform: uppercase;
  letter-spacing: 0.05em;
}
.preloader__loader:before, .preloader__loader:after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(77, 88, 220, 0.4);
  border-radius: 50px;
  opacity: 0;
}
.preloader__loader:before {
  transform: scale(1);
  animation: pulse 2s infinite linear;
}
.preloader__loader:after {
  animation: pulse 2s 1s infinite linear;
}

@keyframes pulse {
  0% {
    transform: scale(0.6);
    opacity: 0;
  }
  33% {
    transform: scale(1);
    opacity: 1;
  }
  100% {
    transform: scale(1.4);
    opacity: 0;
  }
}
.__skeletal {
  font-size: 0;
  color: #838689;
  font-weight: 400;
  background-color: #f1f1f1;
}
.__skeletal * {
  display: none;
}

.toggler {
  display: inline-block;
  width: 56px;
  height: 26px;
  cursor: pointer;
  position: relative;
  user-select: none;
}
.toggler:after {
  content: "";
  display: block;
  position: absolute;
  top: -2px;
  right: -2px;
  bottom: -2px;
  left: -2px;
  z-index: 0;
  border-radius: 5px;
  background-color: rgba(223, 227, 252, 0.75);
  opacity: 0;
  transition: color, opacity, 0.15s ease-in-out;
}
.toggler__icon.fa {
  display: inline-block;
  width: 26px;
  height: 26px;
  position: relative;
  z-index: 1;
  line-height: 26px;
  text-align: center;
  font-size: 20px;
}
.toggler__icon.fa + .toggler__icon.fa {
  margin-left: 4px;
}
.toggler:hover::after, .toggler:active::after {
  opacity: 1;
}
.toggler--def .toggler__icon--def {
  color: #4d58dc;
}
.toggler--def .toggler__icon--alt {
  color: #838689;
}
.toggler--alt .toggler__icon--def {
  color: #838689;
}
.toggler--alt .toggler__icon--alt {
  color: #4d58dc;
}

.badge {
  display: inline-block;
  vertical-align: -0.1em;
  height: 30px;
  min-width: 44px;
  padding: 0 8px;
  text-align: center;
  font-size: 11px;
  font-weight: 500;
  color: #fff;
  line-height: 31px;
  white-space: nowrap;
  border-radius: 15px;
  margin: 0 5px 5px 0;
  background-color: #a3a6ab;
  opacity: 0.85;
  transition: opacity 0.15s linear;
  user-select: none;
}
@media only screen and (max-width: 767.98px) {
  .badge {
    padding: 0 5px;
  }
}
.badge:empty {
  display: none;
}
.badge.__skeletal {
  min-width: 100px;
  border-color: #f1f1f1;
}

a.badge:hover, a.badge:active {
  opacity: 1;
}

.legend {
  display: inline-block;
  vertical-align: middle;
  height: 0.8em;
  width: 0.8em;
  border-radius: 50%;
  margin-right: 5px;
  background-color: #a3a6ab;
  user-select: none;
}

.alias {
  display: inline-block;
  vertical-align: middle;
  min-width: 40px;
  padding: 3px 5px;
  font-size: 11px;
  font-weight: 500;
  white-space: nowrap;
  text-align: center;
  border-radius: 3px;
  margin: 0 5px 5px 0;
  background-color: #f1f1f1;
  user-select: none;
  transition: color, background-color, 0.15s;
}
.alias__overflow {
  display: block;
  max-width: 20ch;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.alias--small {
  min-width: 20px;
  padding: 2px 4px;
  font-size: 10px;
}
@media only screen and (max-width: 991.98px) {
  .alias {
    min-height: 17px;
    font-size: 10px;
    font-weight: 700;
  }
}
.alias.__skeletal {
  height: 22px;
  min-width: 60px;
}

a.alias,
button.alias {
  color: #4d58dc;
  background-color: #edeffd;
  cursor: pointer;
}
a.alias:hover, a.alias:active,
button.alias:hover,
button.alias:active {
  background-color: #dfe3fc;
}

.alias.alias--green, .alias.alias--green:hover, .alias.alias--green:active,
.alias.alias--approval,
.alias.alias--approval:hover,
.alias.alias--approval:active {
  background-color: #cdeccc;
  color: #33a065;
}

.alias.alias--orange, .alias.alias--orange:hover, .alias.alias--orange:active,
.alias.alias--warning,
.alias.alias--warning:hover,
.alias.alias--warning:active {
  background-color: #ffd183;
  color: #C88142;
}

.alias.alias--error, .alias.alias--error:hover, .alias.alias--error:active,
.alias.alias--red,
.alias.alias--red:hover,
.alias.alias--red:active {
  background-color: #feaf97;
  color: #985d4b;
}

.alias.alias--pine, .alias.alias--pine:hover, .alias.alias--pine:active {
  background-color: #e5f7ee;
  color: #56bb85;
}

.alias.alias--blue, .alias.alias--blue:hover, .alias.alias--blue:active {
  background-color: #ddeaf7;
  color: #6b9fcf;
}

.alias.alias--gray, .alias.alias--gray:hover, .alias.alias--gray:active {
  background-color: #e9ebee;
  color: #42464e;
}

:host {
  font-size: 13px;
}

.tag {
  display: inline-block;
  vertical-align: middle;
  padding: 0 0.4em;
  border: 1px solid #e9ebee;
  font-size: 1em;
  color: #42464e;
  border-radius: 4px;
  cursor: pointer;
  user-select: none;
  background-color: #fff;
  margin: 0 5px 5px 0;
}
.tag__inner {
  display: block;
  width: 100%;
  min-width: 10ch;
  max-width: 50ch;
}
.tag:not(.tag--multiline) .tag__inner {
  line-height: 2.16em;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  text-align: center;
}
.tag--multiline .tag__inner {
  width: 210px;
  line-height: 1.5;
  text-align: left;
}
.tag.__skeletal {
  height: 26px;
  min-width: 100px;
}
.tag:not(.__skeletal):not(.__active):hover {
  color: rgba(77, 88, 220, 0.85);
}
.tag.__active:not(.__disabled) {
  border-color: #4d58dc;
  color: #4d58dc;
}
.tag.__disabled {
  opacity: 0.5;
}

.form__row {
  display: flex;
  flex-direction: row;
  width: 100%;
}
.form__row > * {
  display: inline-flex;
}
.form__row--h {
  flex-wrap: nowrap;
  justify-content: space-between;
}
.form__row--h-50 > * {
  flex: auto;
  margin-left: 5px;
  margin-right: 5px;
}
.form__row--v {
  flex-wrap: wrap;
}
.form__row--v > * {
  flex: 0 0 100%;
}
.form__row--v .field + .field {
  margin-top: 10px;
}
.form__row--v .btn {
  width: 100%;
}
.form__row + .form__row {
  margin-top: 15px;
}

.btn {
  min-width: 80px;
  height: 33px;
  line-height: 33px;
  padding: 0 16px;
  font-size: 12px;
  font-weight: 500;
  text-align: center;
  text-transform: uppercase;
  transition: background-color, border, 0.15s linear;
  position: relative;
  cursor: pointer;
  user-select: none;
  border-radius: 3px;
}
.btn:not(.hidden) {
  display: inline-block;
}
.btn:not(.btn--icon-only) {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.btn[disabled] {
  opacity: 0.5;
  cursor: default;
}
.btn .loader {
  display: inline-block;
  pointer-events: none;
  font-size: 14px;
  z-index: 1;
}
.btn.btn--fill {
  line-height: 31px;
}

.btn.btn--flat {
  width: auto;
  min-width: 0;
  padding: 0;
  border: 0;
}
.btn.btn--flat:hover {
  border: 0;
}

.btn--black:not(.btn--fill) {
  color: #27292e;
}
.btn--black:not(.btn--fill) .loader {
  border: 0.2em solid rgba(39, 41, 46, 0.2);
  border-top-color: #27292e;
}
.btn--black:not(.btn--fill).disabled {
  opacity: 1;
  color: #3e424a;
}
.btn--black:not(.btn--flat):not(.btn--icon-only) {
  border: 1px solid #33353c;
}
.btn--black:not(.btn--flat):not(.btn--icon-only).disabled {
  border-color: #3e424a;
}
.btn--black:not(.disabled):hover {
  border-color: #101012;
}
.btn--black:not(.disabled):hover:not(.btn--fill) {
  color: #101012;
}
.btn--black.btn--fill {
  background: #27292e;
  background: linear-gradient(135deg, #27292e 0%, #2c2c34 100%);
  color: #fff;
}
.btn--black.btn--fill.disabled {
  opacity: 1;
  background-color: #33353c;
}
.btn--black.btn--fill:not(.disabled):hover {
  background-color: #101012;
  border-color: #101012;
  background: linear-gradient(150deg, #27292e 0%, #33343c 100%);
}
.btn--black.btn--fill .loader {
  border: 0.2em solid rgba(255, 255, 255, 0.2);
  border-top-color: #fff;
}

.btn--red:not(.btn--fill) {
  color: #f39f96;
}
.btn--red:not(.btn--fill) .loader {
  border: 0.2em solid rgba(243, 159, 150, 0.2);
  border-top-color: #f39f96;
}
.btn--red:not(.btn--fill).disabled {
  opacity: 1;
  color: #f8c9c4;
}
.btn--red:not(.btn--flat):not(.btn--icon-only) {
  border: 1px solid #f6b4ad;
}
.btn--red:not(.btn--flat):not(.btn--icon-only).disabled {
  border-color: #f8c9c4;
}
.btn--red:not(.disabled):hover {
  border-color: #ee7568;
}
.btn--red:not(.disabled):hover:not(.btn--fill) {
  color: #ee7568;
}
.btn--red.btn--fill {
  background: #f39f96;
  background: linear-gradient(135deg, #f39f96 0%, #f4bd9f 100%);
  color: #fff;
}
.btn--red.btn--fill.disabled {
  opacity: 1;
  background-color: #f6b4ad;
}
.btn--red.btn--fill:not(.disabled):hover {
  background-color: #ee7568;
  border-color: #ee7568;
  background: linear-gradient(150deg, #f39f96 0%, #f6c0ad 100%);
}
.btn--red.btn--fill .loader {
  border: 0.2em solid rgba(255, 255, 255, 0.2);
  border-top-color: #fff;
}

.btn--green:not(.btn--fill) {
  color: #2dbf70;
}
.btn--green:not(.btn--fill) .loader {
  border: 0.2em solid rgba(45, 191, 112, 0.2);
  border-top-color: #2dbf70;
}
.btn--green:not(.btn--fill).disabled {
  opacity: 1;
  color: #4bd48a;
}
.btn--green:not(.btn--flat):not(.btn--icon-only) {
  border: 1px solid #36d07c;
}
.btn--green:not(.btn--flat):not(.btn--icon-only).disabled {
  border-color: #4bd48a;
}
.btn--green:not(.disabled):hover {
  border-color: #239658;
}
.btn--green:not(.disabled):hover:not(.btn--fill) {
  color: #239658;
}
.btn--green.btn--fill {
  background: #2dbf70;
  background: linear-gradient(135deg, #2dbf70 0%, #2fc79b 100%);
  color: #fff;
}
.btn--green.btn--fill.disabled {
  opacity: 1;
  background-color: #36d07c;
}
.btn--green.btn--fill:not(.disabled):hover {
  background-color: #239658;
  border-color: #239658;
  background: linear-gradient(150deg, #2dbf70 0%, #36d096 100%);
}
.btn--green.btn--fill .loader {
  border: 0.2em solid rgba(255, 255, 255, 0.2);
  border-top-color: #fff;
}

.btn--purple:not(.btn--fill) {
  color: #4d58dc;
}
.btn--purple:not(.btn--fill) .loader {
  border: 0.2em solid rgba(77, 88, 220, 0.2);
  border-top-color: #4d58dc;
}
.btn--purple:not(.btn--fill).disabled {
  opacity: 1;
  color: #7880e4;
}
.btn--purple:not(.btn--flat):not(.btn--icon-only) {
  border: 1px solid #626ce0;
}
.btn--purple:not(.btn--flat):not(.btn--icon-only).disabled {
  border-color: #7880e4;
}
.btn--purple:not(.disabled):hover {
  border-color: #2835ce;
}
.btn--purple:not(.disabled):hover:not(.btn--fill) {
  color: #2835ce;
}
.btn--purple.btn--fill {
  background: #4d58dc;
  background: linear-gradient(135deg, #4d58dc 0%, #6d56de 100%);
  color: #fff;
}
.btn--purple.btn--fill.disabled {
  opacity: 1;
  background-color: #626ce0;
}
.btn--purple.btn--fill:not(.disabled):hover {
  background-color: #2835ce;
  border-color: #2835ce;
  background: linear-gradient(150deg, #4d58dc 0%, #6e62e0 100%);
}
.btn--purple.btn--fill .loader {
  border: 0.2em solid rgba(255, 255, 255, 0.2);
  border-top-color: #fff;
}

.btn--gray:not(.btn--fill) {
  color: #f0f2fc;
}
.btn--gray:not(.btn--fill) .loader {
  border: 0.2em solid rgba(240, 242, 252, 0.2);
  border-top-color: #f0f2fc;
}
.btn--gray:not(.btn--fill).disabled {
  opacity: 1;
  color: white;
}
.btn--gray:not(.btn--flat):not(.btn--icon-only) {
  border: 1px solid white;
}
.btn--gray:not(.btn--flat):not(.btn--icon-only).disabled {
  border-color: white;
}
.btn--gray:not(.disabled):hover {
  border-color: #c6cdf4;
}
.btn--gray:not(.disabled):hover:not(.btn--fill) {
  color: #c6cdf4;
}
.btn--gray.btn--fill {
  background: #f0f2fc;
  background: linear-gradient(135deg, #f0f2fc 0%, #f9f9fe 100%);
  color: #4d58dc;
}
.btn--gray.btn--fill.disabled {
  opacity: 1;
  background-color: white;
}
.btn--gray.btn--fill:not(.disabled):hover {
  background-color: #c6cdf4;
  border-color: #c6cdf4;
  background: linear-gradient(150deg, #f0f2fc 0%, white 100%);
}
.btn--gray.btn--fill .loader {
  border: 0.2em solid rgba(255, 255, 255, 0.2);
  border-top-color: #fff;
}

.btn--white:not(.btn--fill) {
  color: #fff;
}
.btn--white:not(.btn--fill) .loader {
  border: 0.2em solid rgba(255, 255, 255, 0.2);
  border-top-color: #fff;
}
.btn--white:not(.btn--fill).disabled {
  opacity: 1;
  color: white;
}
.btn--white:not(.btn--flat):not(.btn--icon-only) {
  border: 1px solid white;
}
.btn--white:not(.btn--flat):not(.btn--icon-only).disabled {
  border-color: white;
}
.btn--white:not(.disabled):hover {
  border-color: #e6e6e6;
}
.btn--white:not(.disabled):hover:not(.btn--fill) {
  color: #e6e6e6;
}
.btn--white.btn--fill {
  background: #fff;
  background: linear-gradient(135deg, #fff 0%, white 100%);
  color: #1E2028;
}
.btn--white.btn--fill.disabled {
  opacity: 1;
  background-color: white;
}
.btn--white.btn--fill:not(.disabled):hover {
  background-color: #e6e6e6;
  border-color: #e6e6e6;
  background: linear-gradient(150deg, #fff 0%, white 100%);
}
.btn--white.btn--fill .loader {
  border: 0.2em solid rgba(255, 255, 255, 0.2);
  border-top-color: #fff;
}

.btn.btn--small {
  height: 26px;
  line-height: 26px;
  padding: 0 5px;
  font-size: 11px;
  font-weight: 500;
}
.btn.btn--small .loader {
  font-size: 11px;
}
.btn.btn--small.btn--fill {
  line-height: 25px;
}

.btn.btn--big {
  height: 39px;
  line-height: 39px;
  padding: 0 15px;
  font-size: 14px;
  font-weight: 500;
  border-radius: 5px;
}
.btn.btn--big.btn--fill {
  line-height: 37px;
}

.btn.btn--icon-only {
  width: 1em;
  height: 1em;
  min-width: auto;
  font-size: 22px;
  padding: 0.05em;
  text-align: center;
  line-height: 0;
  border-radius: 50%;
  border: 0;
}
.btn.btn--icon-only::after {
  content: "";
  display: block;
  position: absolute;
  top: -4px;
  right: -4px;
  bottom: -4px;
  left: -4px;
  z-index: 0;
  border-radius: 50%;
  background-color: rgba(223, 227, 252, 0.75);
  opacity: 0;
  transition: color, opacity, 0.15s ease-in-out;
}
.btn.btn--icon-only .fa {
  position: relative;
  width: 1em;
  height: 1em;
  line-height: 1em;
  z-index: 1;
}
.btn.btn--icon-only:hover::after, .btn.btn--icon-only:active::after {
  opacity: 1;
}

.show-more__button {
  width: 100%;
  height: 35px;
  line-height: 33px;
  padding: 0 10px;
  font-size: 14px;
  font-weight: 500;
  color: #4d58dc;
  background-color: #edeffd;
  border: 1px solid #b0baf8;
}
.show-more__button:hover, .show-more__button:active {
  background-color: #dfe3fc;
}

button.btn {
  line-height: normal;
}

.alert {
  width: 100%;
  min-width: 320px;
  padding: 10px 10px 20px 10px;
  font-size: 14px;
  font-weight: 500;
  text-align: left;
  position: fixed;
  left: 0;
  bottom: 0;
  z-index: 100;
}
.alert__close {
  width: 12px;
  height: 12px;
  font-size: 14px;
  position: absolute;
  top: 5px;
  right: 10px;
  text-align: center;
  line-height: 12px;
  cursor: pointer;
}
.alert--error {
  color: #ff3a01;
  background-color: #fbe5e1;
}
.alert--error .alert__close {
  color: #ff3a01;
}

.btn-wrap {
  width: 100%;
}
.btn-wrap .btn {
  margin: 0 auto;
}

.filters-group {
  width: auto;
  display: flex;
  align-items: flex-start;
  flex-flow: row wrap;
  justify-content: flex-start;
}
.filters-group .filter,
.filters-group .btn {
  margin: 0 5px 5px 0;
}

.filter {
  display: block;
  float: left;
  width: auto;
  height: 26px;
  position: relative;
  line-height: 26px;
  font-size: 12px;
  transition: background-color 0.15s ease-in;
  cursor: pointer;
  padding: 0 8px 0 8px;
  border-radius: 15px;
  background-color: #7880e4;
  user-select: none;
}
.filter, .filter * {
  color: #fff;
}
.filter .icon {
  position: relative;
  top: -1px;
  vertical-align: middle;
  margin-left: 2px;
  font-size: 20px;
  height: 20px;
  width: 20px;
}
.filter:hover {
  background-color: #4d58dc;
}
.filter.__active .fa {
  transform: scale(1, -1) translateZ(0);
}
.filter.__active, .filter.__active:hover {
  background-color: #4d58dc;
}

.tip {
  width: 100%;
  font-size: 12px;
  color: #838689;
  margin-bottom: 20px;
}

code {
  display: inline-block;
  padding: 0 4px;
  border-radius: 3px;
  color: #c0341d;
  background-color: #fbe5e1;
  margin-top: 2px;
  margin-bottom: 2px;
}

table.table {
  width: 100%;
  color: #27292e;
  background: #fff;
  border: 1px solid #e9ebee;
  font-size: 13px;
  border-collapse: collapse;
}
table.table thead th,
table.table tfoot th {
  color: #42464e;
  text-align: left;
  background: #fbfbfc;
}
table.table caption {
  padding: 0.5em;
}
table.table th,
table.table td {
  background: #fff;
  border: 1px solid #e3e4e8;
  word-wrap: normal;
}
table.table th:after,
table.table td:after {
  content: " ";
  display: none;
  height: 0;
}
table.table th,
table.table td {
  padding: 0.5em;
}
table.table--sticky {
  border-collapse: separate;
}
table.table--sticky thead th:first-of-type {
  position: sticky;
  left: 0;
  z-index: 2;
}
table.table--sticky tr td:first-of-type {
  position: sticky;
  left: 0;
  z-index: 2;
}
table.table--zebra tbody tr:not(:only-child):nth-of-type(even) td {
  background: #fbfbfc;
}
table.table .__cell-width-small:after {
  display: block;
  width: 8em;
}
table.table .__cell-width-medium:after {
  display: block;
  width: 12em;
}
table.table .__cell-width-large:after {
  display: block;
  width: 14em;
}
table.table .__cell-width-very-large:after {
  display: block;
  width: 24em;
}

.table-section {
  width: 100%;
  max-width: 100%;
  overflow-x: auto;
}

.panel {
  position: relative;
  border-radius: 10px;
  border: 1px solid;
  padding: 10px 15px;
  margin-bottom: 18px;
  background-color: #fff;
  color: #64676f;
  border-color: #e9ebee;
}
.panel__group {
  flex-flow: row wrap;
  position: relative;
}
.panel .panel__group + .panel__group {
  margin-top: 20px;
}
.panel ul {
  margin: 0;
}
.panel--dark {
  background-color: #2d323b;
  color: #64676f;
  border-color: #323439;
}
.panel--w-shadow {
  border: none;
  box-shadow: 0 1px 10px rgba(39, 41, 46, 0.25);
}
.panel--w-colored-shadow {
  border: none;
  box-shadow: 0 1px 10px rgba(36, 48, 184, 0.25);
}
.panel--transparent {
  border: none;
  background-color: transparent;
}

.term-w-p::before {
  font-size: 0.6em;
  margin-right: 3px;
  vertical-align: middle;
}
.term-w-p--positive::before {
  content: "▲";
  color: #2dbf70;
}
.term-w-p--negative::before {
  content: "▼";
  color: #f39f96;
}

.list.list--terms-w-p li.term-w-p {
  padding-left: 1.8ex;
}
.list.list--terms-w-p li.term-w-p::before {
  position: absolute;
  left: 0;
  top: 0.6em;
}

.hint-panel {
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 10px;
  align-content: flex-start;
  align-items: flex-start;
  justify-items: start;
  justify-content: space-between;
  width: 100%;
  height: 120px;
  padding: 12px;
  border-radius: 5px;
  background: linear-gradient(to right, #ff922c 0%, #FFC769 100%);
  font-size: 14px;
  white-space: normal;
  color: #fff;
}
.hint-panel::after {
  content: " ";
  display: block;
  width: 33%;
  min-width: 100px;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: 1;
}
.hint-panel__title {
  width: 100%;
  font-size: 1em;
  font-weight: 500;
  position: relative;
  z-index: 2;
  padding-right: 20%;
  margin-top: 3px;
}
@media only screen and (max-width: 1199.98px) {
  .hint-panel__title {
    padding: 0;
  }
}
.hint-panel a.hint-panel__button {
  line-height: 26px;
}
.hint-panel button.hint-panel__button {
  appearance: none;
}
.hint-panel__button {
  height: 26px;
  padding: 0 10px;
  margin: 5px 0;
  border-radius: 5px;
  font-size: 1em;
  font-weight: 500;
  position: relative;
  z-index: 2;
  background-color: #fff;
  color: #fdB338;
  white-space: nowrap;
  cursor: pointer;
}
@media only screen and (max-width: 1199.98px) {
  .hint-panel__button {
    margin: 0 0 0 10px;
  }
}
@media only screen and (max-width: 1199.98px) {
  .hint-panel {
    height: auto;
    flex-direction: row;
    flex-wrap: nowrap;
  }
  .hint-panel__title {
    padding: 0;
  }
}

.link {
  font-weight: 500;
  cursor: pointer;
  position: relative;
  color: #4d58dc;
}
.link:hover, .link:active {
  color: #5ca4df;
}
.link--gray {
  color: #7e8191;
}
.link--gray:hover, .link--gray:active {
  color: #656877;
}
.link--white {
  color: #fff;
}
.link--white:hover, .link--white:active {
  color: #e6e6e6;
}
.link--underline {
  text-decoration: underline;
}
.link--anchor, .link--term {
  color: #7e8191;
  border-bottom: 1px dashed currentColor;
}
.link--anchor:hover, .link--anchor:active, .link--term:hover, .link--term:active {
  color: #656877;
}
.link--term {
  font-style: italic;
}
.link--gene {
  color: #1b75d0;
  font-weight: 700;
  text-decoration: underline;
}
.link--gene:hover {
  color: #3275b8;
}
.link--gene:active {
  color: #1b75cf;
}

.typography ul.list, .typography ol.list {
  width: 100%;
}
.typography ul.list li, .typography ol.list li {
  width: 100%;
  position: relative;
  word-break: break-word;
  hyphens: auto;
}
.typography ul.list li .alias, .typography ol.list li .alias {
  vertical-align: top;
}
.typography ul.list li .callout, .typography ol.list li .callout {
  display: block;
  float: left;
}
.typography ul.list li + li,
.typography ul.list li > ul,
.typography ul.list li > ol, .typography ol.list li + li,
.typography ol.list li > ul,
.typography ol.list li > ol {
  margin-top: 0.8ex;
}
.typography ul.list--bulletted li {
  padding-left: 1.8ex;
}
.typography ul.list--bulletted li:before {
  content: " ";
  display: block;
  width: 5px;
  height: 5px;
  border-radius: 50%;
  background-color: #4d58dc;
  position: absolute;
  left: 0;
  top: 1ex;
  z-index: 0;
}
.typography dl.list {
  display: inline-block;
  width: 100%;
}
.typography dl.list dt, .typography dl.list dd {
  float: left;
}
.typography dl.list dt {
  clear: left;
  margin-right: 1ch;
  font-weight: 500;
}
.typography dl.list dt span {
  font-weight: 400;
}
.typography dl.list dt:not(:first-of-type),
.typography dl.list dd:not(:first-of-type) {
  margin-top: 0.8ex;
}
.typography dl.list--stacked dt,
.typography dl.list--stacked dd {
  width: 100%;
}
.typography article,
.typography section {
  width: 100%;
}
@media only screen and (min-width: 992px) {
  .typography article,
.typography section {
    font-size: calc(0.2vw + 13px);
  }
}
@media only screen and (min-width: 768px) and (max-width: 991.98px) {
  .typography article,
.typography section {
    font-size: 15px;
  }
}
@media only screen and (max-width: 767.98px) {
  .typography article,
.typography section {
    font-size: 14px;
  }
}
.typography article b,
.typography article strong,
.typography section b,
.typography section strong {
  font-weight: 500;
}
.typography article h1,
.typography article h2,
.typography article h3,
.typography article h4,
.typography article h5,
.typography article h6,
.typography section h1,
.typography section h2,
.typography section h3,
.typography section h4,
.typography section h5,
.typography section h6 {
  width: 100%;
  color: #575757;
  font-weight: 500;
}
.typography article h1,
.typography section h1 {
  font-size: calc(0.2vw + 25px);
  color: #575757;
}
.typography article h2,
.typography section h2 {
  font-size: calc(0.2vw + 20px);
}
.typography article h3,
.typography section h3 {
  font-size: calc(0.2vw + 16px);
}
.typography article h4,
.typography section h4 {
  font-size: calc(0.2vw + 14px);
}
.typography article h5,
.typography section h5 {
  font-size: calc(0.2vw + 13px);
}
.typography article h6,
.typography section h6 {
  font-size: 14px;
}
.typography article * + h1,
.typography article * + h2,
.typography section * + h1,
.typography section * + h2 {
  margin-top: 3ex;
}
.typography article * + h3,
.typography article * + h4,
.typography article * + h5,
.typography article * + h6,
.typography article * + p,
.typography article * + ul,
.typography article * + ol,
.typography article * + blockquote,
.typography article * + app-code-block,
.typography section * + h3,
.typography section * + h4,
.typography section * + h5,
.typography section * + h6,
.typography section * + p,
.typography section * + ul,
.typography section * + ol,
.typography section * + blockquote,
.typography section * + app-code-block {
  margin-top: 2.5ex;
}
.typography article h3 + *,
.typography article h4 + *,
.typography article h5 + *,
.typography article h6 + *,
.typography article blockquote + *,
.typography section h3 + *,
.typography section h4 + *,
.typography section h5 + *,
.typography section h6 + *,
.typography section blockquote + * {
  margin-top: 2ex;
}
.typography article > a:not(.btn), .typography article > p a:not(.btn),
.typography section > a:not(.btn),
.typography section > p a:not(.btn) {
  font-weight: 500;
  color: #4d58dc;
}
.typography article > a:not(.btn):hover, .typography article > a:not(.btn):active, .typography article > p a:not(.btn):hover, .typography article > p a:not(.btn):active,
.typography section > a:not(.btn):hover,
.typography section > a:not(.btn):active,
.typography section > p a:not(.btn):hover,
.typography section > p a:not(.btn):active {
  color: #5ca4df;
}
.typography blockquote {
  display: inline-block;
  width: 100%;
  padding: 15px;
  background-color: #f0f2fc;
  border-left: 4px solid #b7bcf1;
  color: #27292e;
  font-size: calc(0.2vw + 14px);
}
.typography article + article,
.typography article + section,
.typography section + section,
.typography section + article,
.typography h1 + section,
.typography h1 + article,
.typography h2 + section,
.typography h2 + article {
  margin-top: 30px;
}
.typography article + h3 {
  margin-top: 3ex;
}
.typography .reference {
  font-size: 80%;
  margin-top: 1em;
}
.typography .reference p + p,
.typography .reference ul + p,
.typography .reference ol + p {
  margin-top: 0.8em;
}

.counter {
  display: block;
  padding: 0 5px;
  height: 26px;
  width: auto;
  min-width: 26px;
  white-space: nowrap;
  line-height: 26px;
  text-align: center;
  background-color: #FF4E1A;
  color: #fff;
  font-size: 14px;
  border-radius: 13px;
}

/* Grid mixins */ /*
 * Миксины для медиа-запросов
 */
/* Основные разрешения
================================================*/
/* Desktop - widescreen
------------------------------------------------*/
/* Desktop - small desktop
------------------------------------------------*/
/* Mobile - tablet
------------------------------------------------*/
/* Mobile - smartphone (Portrait, 320px)
------------------------------------------------*/
/* Дополнительные разрешения
================================================*/
/* Mobile - phone only (Optional, 320px)
------------------------------------------------*/
/* Mobile - phablet (Optional, 480px)
------------------------------------------------*/
/* Брейкпоинты, объединяющие несколько разрешений
------------------------------------------------*/
.page {
  display: table;
  width: 100%;
  height: 100%;
  position: relative;
}
.page ul.list, .page ol.list {
  width: 100%;
}
.page ul.list li, .page ol.list li {
  width: 100%;
  position: relative;
  word-break: break-word;
  hyphens: auto;
}
.page ul.list li .alias, .page ol.list li .alias {
  vertical-align: top;
}
.page ul.list li .callout, .page ol.list li .callout {
  display: block;
  float: left;
}
.page ul.list li + li,
.page ul.list li > ul,
.page ul.list li > ol, .page ol.list li + li,
.page ol.list li > ul,
.page ol.list li > ol {
  margin-top: 0.8ex;
}
.page ul.list--bulletted li {
  padding-left: 1.8ex;
}
.page ul.list--bulletted li:before {
  content: " ";
  display: block;
  width: 5px;
  height: 5px;
  border-radius: 50%;
  background-color: #4d58dc;
  position: absolute;
  left: 0;
  top: 1ex;
  z-index: 0;
}
.page dl.list {
  display: inline-block;
  width: 100%;
}
.page dl.list dt, .page dl.list dd {
  float: left;
}
.page dl.list dt {
  clear: left;
  margin-right: 1ch;
  font-weight: 500;
}
.page dl.list dt span {
  font-weight: 400;
}
.page dl.list dt:not(:first-of-type),
.page dl.list dd:not(:first-of-type) {
  margin-top: 0.8ex;
}
.page dl.list--stacked dt,
.page dl.list--stacked dd {
  width: 100%;
}
.page article,
.page section {
  width: 100%;
}
@media only screen and (min-width: 992px) {
  .page article,
.page section {
    font-size: calc(0.2vw + 13px);
  }
}
@media only screen and (min-width: 768px) and (max-width: 991.98px) {
  .page article,
.page section {
    font-size: 15px;
  }
}
@media only screen and (max-width: 767.98px) {
  .page article,
.page section {
    font-size: 14px;
  }
}
.page article b,
.page article strong,
.page section b,
.page section strong {
  font-weight: 500;
}
.page article h1,
.page article h2,
.page article h3,
.page article h4,
.page article h5,
.page article h6,
.page section h1,
.page section h2,
.page section h3,
.page section h4,
.page section h5,
.page section h6 {
  width: 100%;
  color: #575757;
  font-weight: 500;
}
.page article h1,
.page section h1 {
  font-size: calc(0.2vw + 25px);
  color: #575757;
}
.page article h2,
.page section h2 {
  font-size: calc(0.2vw + 20px);
}
.page article h3,
.page section h3 {
  font-size: calc(0.2vw + 16px);
}
.page article h4,
.page section h4 {
  font-size: calc(0.2vw + 14px);
}
.page article h5,
.page section h5 {
  font-size: calc(0.2vw + 13px);
}
.page article h6,
.page section h6 {
  font-size: 14px;
}
.page article * + h1,
.page article * + h2,
.page section * + h1,
.page section * + h2 {
  margin-top: 3ex;
}
.page article * + h3,
.page article * + h4,
.page article * + h5,
.page article * + h6,
.page article * + p,
.page article * + ul,
.page article * + ol,
.page article * + blockquote,
.page article * + app-code-block,
.page section * + h3,
.page section * + h4,
.page section * + h5,
.page section * + h6,
.page section * + p,
.page section * + ul,
.page section * + ol,
.page section * + blockquote,
.page section * + app-code-block {
  margin-top: 2.5ex;
}
.page article h3 + *,
.page article h4 + *,
.page article h5 + *,
.page article h6 + *,
.page article blockquote + *,
.page section h3 + *,
.page section h4 + *,
.page section h5 + *,
.page section h6 + *,
.page section blockquote + * {
  margin-top: 2ex;
}
.page article > a:not(.btn), .page article > p a:not(.btn),
.page section > a:not(.btn),
.page section > p a:not(.btn) {
  font-weight: 500;
  color: #4d58dc;
}
.page article > a:not(.btn):hover, .page article > a:not(.btn):active, .page article > p a:not(.btn):hover, .page article > p a:not(.btn):active,
.page section > a:not(.btn):hover,
.page section > a:not(.btn):active,
.page section > p a:not(.btn):hover,
.page section > p a:not(.btn):active {
  color: #5ca4df;
}
.page blockquote {
  display: inline-block;
  width: 100%;
  padding: 15px;
  background-color: #f0f2fc;
  border-left: 4px solid #b7bcf1;
  color: #27292e;
  font-size: calc(0.2vw + 14px);
}
.page article + article,
.page article + section,
.page section + section,
.page section + article,
.page h1 + section,
.page h1 + article,
.page h2 + section,
.page h2 + article {
  margin-top: 30px;
}
.page article + h3 {
  margin-top: 3ex;
}
.page .reference {
  font-size: 80%;
  margin-top: 1em;
}
.page .reference p + p,
.page .reference ul + p,
.page .reference ol + p {
  margin-top: 0.8em;
}
.page__inner {
  display: table-cell;
  vertical-align: top;
}
.page__inner .page__title {
  margin-bottom: 15px;
}
.page__inner .page__title h1 {
  font-size: 28px;
  color: #2d323b;
}
.page__inner .page__title p {
  font-size: 14px;
  color: #838689;
}
.page__inner .page__title .hint {
  margin-top: 5px;
}
.page__inner .page__subtitle {
  font-size: 16px;
  color: #27292e;
  font-weight: 500;
  margin-bottom: 8px;
}
.page--dummy {
  min-height: calc(80vh - 64px);
}
.page--dummy .page__inner {
  vertical-align: middle;
}
@media only screen and (min-width: 1200px) {
  .page app-sidebar {
    position: sticky;
    top: 70px;
    margin-bottom: 60px;
  }
}
@media only screen and (max-width: 1199.98px) {
  .page app-sidebar {
    flex: 0 0 100%;
    margin-bottom: 20px;
  }
}

.text-page__header {
  min-height: 140px;
  background: linear-gradient(to bottom, #f0f2fc, #fbfbfc);
  padding: 30px 0 35px 0;
  margin-bottom: 20px;
  border-radius: 0 0 20px 20px;
}
.text-page__header .page__title,
.text-page__header .header__description,
.text-page__header .header__button {
  width: 100%;
  text-align: center;
}
.text-page__header .page__title h1 {
  font-weight: 500;
}
.text-page__header .header__description {
  font-size: calc(0.2vw + 18px);
  color: #42464e;
  padding: 0 4vw;
}
.text-page__header .header__button {
  margin-top: 30px;
}
.text-page__header .header__button a {
  min-width: 120px;
}
.text-page__articles {
  padding: 20px 0;
}

.news-page__news.col {
  font-size: 18px;
}
.news-page__news.col app-news-list .news__item {
  animation: fadeIn 0.5s linear forwards;
}
.news-page__news.col .news__skeleton {
  margin-top: 20px;
}

.search-page .page__inner {
  padding: 20px 0 60px 0;
}
.search-page__header {
  margin-bottom: 25px;
}
@media only screen and (min-width: 1200px) and (max-width: 1399.98px) {
  .search-page__container {
    max-width: calc(100% - 60px);
  }
}
.search-page__container .grid-container {
  display: grid;
  grid-gap: 20px;
}
.search-page__container .grid-container__sidebar-column--mobile {
  order: 2;
}
.search-page__container .grid-container__sidebar-column--desktop {
  padding-right: 20px;
}
.search-page__container .grid-container__content-column {
  overflow: hidden;
}
@media only screen and (max-width: 1199.98px) {
  .search-page__container .grid-container {
    grid-template-columns: 1fr;
    grid-template-rows: auto auto;
  }
}
@media only screen and (min-width: 1200px) {
  .search-page__container .grid-container {
    grid-template-columns: 260px auto;
    grid-template-rows: 100%;
  }
}
.search-page__container .wizard-banner-wrapper,
.search-page__container .hint-wrapper,
.search-page__container .filters-wrapper {
  width: 100%;
  min-width: 0;
  padding: 0 5px;
}
.search-page__container .wizard-banner-wrapper .skeleton,
.search-page__container .hint-wrapper .skeleton,
.search-page__container .filters-wrapper .skeleton {
  margin: 0;
}

.__text-left, center, right {
  text-align: "left", "center", "right";
}

.__text-left, center, right {
  text-align: "left", "center", "right";
}

.__text-left, center, right {
  text-align: "left", "center", "right";
}

.__m-0 {
  margin: 0px !important;
}

.__mt-0 {
  margin-top: 0px !important;
}

.__mb-0 {
  margin-bottom: 0px !important;
}

.__ml-0 {
  margin-left: 0px !important;
}

.__mr-0 {
  margin-right: 0px !important;
}

.__ml-minus-0 {
  margin-left: -0px;
}

.__mr-minus-0 {
  margin-right: -0px;
}

.__m-5 {
  margin: 5px !important;
}

.__mt-5 {
  margin-top: 5px !important;
}

.__mb-5 {
  margin-bottom: 5px !important;
}

.__ml-5 {
  margin-left: 5px !important;
}

.__mr-5 {
  margin-right: 5px !important;
}

.__ml-minus-5 {
  margin-left: -5px;
}

.__mr-minus-5 {
  margin-right: -5px;
}

.__m-10 {
  margin: 10px !important;
}

.__mt-10 {
  margin-top: 10px !important;
}

.__mb-10 {
  margin-bottom: 10px !important;
}

.__ml-10 {
  margin-left: 10px !important;
}

.__mr-10 {
  margin-right: 10px !important;
}

.__ml-minus-10 {
  margin-left: -10px;
}

.__mr-minus-10 {
  margin-right: -10px;
}

.__m-15 {
  margin: 15px !important;
}

.__mt-15 {
  margin-top: 15px !important;
}

.__mb-15 {
  margin-bottom: 15px !important;
}

.__ml-15 {
  margin-left: 15px !important;
}

.__mr-15 {
  margin-right: 15px !important;
}

.__ml-minus-15 {
  margin-left: -15px;
}

.__mr-minus-15 {
  margin-right: -15px;
}

.__m-20 {
  margin: 20px !important;
}

.__mt-20 {
  margin-top: 20px !important;
}

.__mb-20 {
  margin-bottom: 20px !important;
}

.__ml-20 {
  margin-left: 20px !important;
}

.__mr-20 {
  margin-right: 20px !important;
}

.__ml-minus-20 {
  margin-left: -20px;
}

.__mr-minus-20 {
  margin-right: -20px;
}

.__m-25 {
  margin: 25px !important;
}

.__mt-25 {
  margin-top: 25px !important;
}

.__mb-25 {
  margin-bottom: 25px !important;
}

.__ml-25 {
  margin-left: 25px !important;
}

.__mr-25 {
  margin-right: 25px !important;
}

.__ml-minus-25 {
  margin-left: -25px;
}

.__mr-minus-25 {
  margin-right: -25px;
}

.__m-30 {
  margin: 30px !important;
}

.__mt-30 {
  margin-top: 30px !important;
}

.__mb-30 {
  margin-bottom: 30px !important;
}

.__ml-30 {
  margin-left: 30px !important;
}

.__mr-30 {
  margin-right: 30px !important;
}

.__ml-minus-30 {
  margin-left: -30px;
}

.__mr-minus-30 {
  margin-right: -30px;
}

.__m-35 {
  margin: 35px !important;
}

.__mt-35 {
  margin-top: 35px !important;
}

.__mb-35 {
  margin-bottom: 35px !important;
}

.__ml-35 {
  margin-left: 35px !important;
}

.__mr-35 {
  margin-right: 35px !important;
}

.__ml-minus-35 {
  margin-left: -35px;
}

.__mr-minus-35 {
  margin-right: -35px;
}

.__m-40 {
  margin: 40px !important;
}

.__mt-40 {
  margin-top: 40px !important;
}

.__mb-40 {
  margin-bottom: 40px !important;
}

.__ml-40 {
  margin-left: 40px !important;
}

.__mr-40 {
  margin-right: 40px !important;
}

.__ml-minus-40 {
  margin-left: -40px;
}

.__mr-minus-40 {
  margin-right: -40px;
}

.__m-45 {
  margin: 45px !important;
}

.__mt-45 {
  margin-top: 45px !important;
}

.__mb-45 {
  margin-bottom: 45px !important;
}

.__ml-45 {
  margin-left: 45px !important;
}

.__mr-45 {
  margin-right: 45px !important;
}

.__ml-minus-45 {
  margin-left: -45px;
}

.__mr-minus-45 {
  margin-right: -45px;
}

.__m-50 {
  margin: 50px !important;
}

.__mt-50 {
  margin-top: 50px !important;
}

.__mb-50 {
  margin-bottom: 50px !important;
}

.__ml-50 {
  margin-left: 50px !important;
}

.__mr-50 {
  margin-right: 50px !important;
}

.__ml-minus-50 {
  margin-left: -50px;
}

.__mr-minus-50 {
  margin-right: -50px;
}

.__m-60 {
  margin: 60px !important;
}

.__mt-60 {
  margin-top: 60px !important;
}

.__mb-60 {
  margin-bottom: 60px !important;
}

.__ml-60 {
  margin-left: 60px !important;
}

.__mr-60 {
  margin-right: 60px !important;
}

.__ml-minus-60 {
  margin-left: -60px;
}

.__mr-minus-60 {
  margin-right: -60px;
}

.__m-80 {
  margin: 80px !important;
}

.__mt-80 {
  margin-top: 80px !important;
}

.__mb-80 {
  margin-bottom: 80px !important;
}

.__ml-80 {
  margin-left: 80px !important;
}

.__mr-80 {
  margin-right: 80px !important;
}

.__ml-minus-80 {
  margin-left: -80px;
}

.__mr-minus-80 {
  margin-right: -80px;
}

.__m-100 {
  margin: 100px !important;
}

.__mt-100 {
  margin-top: 100px !important;
}

.__mb-100 {
  margin-bottom: 100px !important;
}

.__ml-100 {
  margin-left: 100px !important;
}

.__mr-100 {
  margin-right: 100px !important;
}

.__ml-minus-100 {
  margin-left: -100px;
}

.__mr-minus-100 {
  margin-right: -100px;
}

.__m-120 {
  margin: 120px !important;
}

.__mt-120 {
  margin-top: 120px !important;
}

.__mb-120 {
  margin-bottom: 120px !important;
}

.__ml-120 {
  margin-left: 120px !important;
}

.__mr-120 {
  margin-right: 120px !important;
}

.__ml-minus-120 {
  margin-left: -120px;
}

.__mr-minus-120 {
  margin-right: -120px;
}

.__p-0 {
  padding: 0px !important;
}

.__pt-0 {
  padding-top: 0px !important;
}

.__pb-0 {
  padding-bottom: 0px !important;
}

.__pl-0 {
  padding-left: 0px !important;
}

.__pr-0 {
  padding-right: 0px !important;
}

.__p-5 {
  padding: 5px !important;
}

.__pt-5 {
  padding-top: 5px !important;
}

.__pb-5 {
  padding-bottom: 5px !important;
}

.__pl-5 {
  padding-left: 5px !important;
}

.__pr-5 {
  padding-right: 5px !important;
}

.__p-10 {
  padding: 10px !important;
}

.__pt-10 {
  padding-top: 10px !important;
}

.__pb-10 {
  padding-bottom: 10px !important;
}

.__pl-10 {
  padding-left: 10px !important;
}

.__pr-10 {
  padding-right: 10px !important;
}

.__p-15 {
  padding: 15px !important;
}

.__pt-15 {
  padding-top: 15px !important;
}

.__pb-15 {
  padding-bottom: 15px !important;
}

.__pl-15 {
  padding-left: 15px !important;
}

.__pr-15 {
  padding-right: 15px !important;
}

.__p-20 {
  padding: 20px !important;
}

.__pt-20 {
  padding-top: 20px !important;
}

.__pb-20 {
  padding-bottom: 20px !important;
}

.__pl-20 {
  padding-left: 20px !important;
}

.__pr-20 {
  padding-right: 20px !important;
}

.__p-25 {
  padding: 25px !important;
}

.__pt-25 {
  padding-top: 25px !important;
}

.__pb-25 {
  padding-bottom: 25px !important;
}

.__pl-25 {
  padding-left: 25px !important;
}

.__pr-25 {
  padding-right: 25px !important;
}

.__p-30 {
  padding: 30px !important;
}

.__pt-30 {
  padding-top: 30px !important;
}

.__pb-30 {
  padding-bottom: 30px !important;
}

.__pl-30 {
  padding-left: 30px !important;
}

.__pr-30 {
  padding-right: 30px !important;
}

.__p-35 {
  padding: 35px !important;
}

.__pt-35 {
  padding-top: 35px !important;
}

.__pb-35 {
  padding-bottom: 35px !important;
}

.__pl-35 {
  padding-left: 35px !important;
}

.__pr-35 {
  padding-right: 35px !important;
}

.__p-40 {
  padding: 40px !important;
}

.__pt-40 {
  padding-top: 40px !important;
}

.__pb-40 {
  padding-bottom: 40px !important;
}

.__pl-40 {
  padding-left: 40px !important;
}

.__pr-40 {
  padding-right: 40px !important;
}

.__p-45 {
  padding: 45px !important;
}

.__pt-45 {
  padding-top: 45px !important;
}

.__pb-45 {
  padding-bottom: 45px !important;
}

.__pl-45 {
  padding-left: 45px !important;
}

.__pr-45 {
  padding-right: 45px !important;
}

.__p-50 {
  padding: 50px !important;
}

.__pt-50 {
  padding-top: 50px !important;
}

.__pb-50 {
  padding-bottom: 50px !important;
}

.__pl-50 {
  padding-left: 50px !important;
}

.__pr-50 {
  padding-right: 50px !important;
}

.__p-60 {
  padding: 60px !important;
}

.__pt-60 {
  padding-top: 60px !important;
}

.__pb-60 {
  padding-bottom: 60px !important;
}

.__pl-60 {
  padding-left: 60px !important;
}

.__pr-60 {
  padding-right: 60px !important;
}

.__p-80 {
  padding: 80px !important;
}

.__pt-80 {
  padding-top: 80px !important;
}

.__pb-80 {
  padding-bottom: 80px !important;
}

.__pl-80 {
  padding-left: 80px !important;
}

.__pr-80 {
  padding-right: 80px !important;
}

.__p-100 {
  padding: 100px !important;
}

.__pt-100 {
  padding-top: 100px !important;
}

.__pb-100 {
  padding-bottom: 100px !important;
}

.__pl-100 {
  padding-left: 100px !important;
}

.__pr-100 {
  padding-right: 100px !important;
}

.__p-120 {
  padding: 120px !important;
}

.__pt-120 {
  padding-top: 120px !important;
}

.__pb-120 {
  padding-bottom: 120px !important;
}

.__pl-120 {
  padding-left: 120px !important;
}

.__pr-120 {
  padding-right: 120px !important;
}

.__w-0 {
  width: 0px;
}

.__w-5 {
  width: 5px;
}

.__w-10 {
  width: 10px;
}

.__w-15 {
  width: 15px;
}

.__w-20 {
  width: 20px;
}

.__w-25 {
  width: 25px;
}

.__w-30 {
  width: 30px;
}

.__w-35 {
  width: 35px;
}

.__w-40 {
  width: 40px;
}

.__w-45 {
  width: 45px;
}

.__w-50 {
  width: 50px;
}

.__w-60 {
  width: 60px;
}

.__w-80 {
  width: 80px;
}

.__w-100 {
  width: 100px;
}

.__w-120 {
  width: 120px;
}

.__h-0 {
  width: 0px;
}

.__h-5 {
  width: 5px;
}

.__h-10 {
  width: 10px;
}

.__h-15 {
  width: 15px;
}

.__h-20 {
  width: 20px;
}

.__h-25 {
  width: 25px;
}

.__h-30 {
  width: 30px;
}

.__h-35 {
  width: 35px;
}

.__h-40 {
  width: 40px;
}

.__h-45 {
  width: 45px;
}

.__h-50 {
  width: 50px;
}

.__h-60 {
  width: 60px;
}

.__h-80 {
  width: 80px;
}

.__h-100 {
  width: 100px;
}

.__h-120 {
  width: 120px;
}

.__w-auto {
  width: auto;
}

@media only screen and (min-width: 1200px) {
  .__w-content {
    max-width: calc(100vw - 260px);
  }
}
@media only screen and (max-width: 1199.98px) {
  .__w-content {
    max-width: calc(100vw - 40px);
  }
}

.__w-content-fluid {
  max-width: calc(100vw - 45px);
}

.__nowrap {
  white-space: nowrap;
}

@media only screen and (min-width: 992px) {
  .__only-on-mobile {
    display: none !important;
  }
}
@media only screen and (max-width: 991.98px) {
  .__only-on-desktop {
    display: none !important;
  }
}
.__disabled {
  pointer-events: none;
}

.__brand-color-entrez {
  background-color: #85add6;
}

.__brand-color-geneage {
  background-color: #ff803b;
}

.__brand-color-uniprot {
  background-color: #97cbdf;
}

.__brand-color-disgenet {
  background-color: #9c9ade;
}

.__brand-color-biogrid {
  background-color: #fdb338;
}

.__brand-color-ensembl {
  background-color: #697ec9;
}

.__brand-color-open-genes {
  background-color: #4d58dc;
}