// Badge
.badge {
  display: inline-block;
  vertical-align: -0.1em;
  height: 30px;
  min-width: 44px;
  padding: 0 8px;
  text-align: center;
  font-size: 11px;
  font-weight: $font-weight-medium;
  color: #fff;
  line-height: 31px;
  white-space: nowrap;
  border-radius: 15px;
  margin: 0 5px 5px 0;
  background-color: $color-gray-halfing;
  opacity: .85;
  transition: opacity .15s linear;
  user-select: none;

  @include resolution_smartphone {
    padding: 0 5px;
  }

  &:empty {
    display: none;
  }

  // Class for skeleton screens
  &.__skeletal {
    min-width: 100px;
    border-color: $color-gray-soft;
  }
}

a.badge {
  &:hover,
  &:active {
    opacity: 1;
  }
}
