html, body, div, span, applet, object, iframe, h1, h2, h3, h4, h5, h6, p, blockquote, pre, a, abbr, acronym, address, big, cite, code, del, dfn, em, img, ins, kbd, q, s, samp, small, strike, strong, sub, sup, tt, var, center, dl, dt, dd, ol, ul, li, fieldset, form, label, legend, table, caption, tbody, tfoot, thead, tr, th, td, article, aside, canvas, details, embed, figure, figcaption, footer, header, menu, nav, output, ruby, section, summary, time, mark, audio, button {
  margin: 0;
  padding: 0;
  border: 0;
  font: inherit;
  vertical-align: baseline;
  word-wrap: break-word;
  line-height: $font-line-height;
  outline: 0;
  background: transparent;
  text-rendering: auto;
}

html {
  min-height: 100%;
  * {
    box-sizing: border-box;
    -webkit-tap-highlight-color: rgba($color-general, .15);
  }
}

input,
button {
  &:focus {
    outline: none;
    border-color: $color-general;
  }
}

body {
  min-height: 100%;
  overflow-y: scroll;
  -webkit-text-size-adjust: none;
  font-family: 'Roboto', Arial, Helvetica, sans-serif;
  font-size: $font-size;
  color: $color-black;
  font-weight: 400;
  line-height: 1.3;
  background: #fff;
  position: relative;
  -webkit-font-smoothing: antialiased;
  cursor: default;
  touch-action: manipulation;
  box-sizing: border-box;
}

h1, h2, h3, h4, h5, h6 {
  margin: 0;
  padding: 0;
  line-height: 1.3;
  word-break: break-word;
  hyphens: auto;
}

a {
  text-decoration: none;
  color: inherit;
}

button {
  border: 0;
  outline: 0;
  appearance: none;
  cursor: pointer;
}

article, aside, details, figcaption, figure, footer, header, menu, nav, section {
  display: block;
}

blockquote, q {
  quotes: none;
}

ul {
  list-style: none;
  li {
    display: inline-block;
  }
}

ol {
  padding-left: 1.7em;
}

img,
svg {
  display: inline-block;
  width: 100%;
  height: auto;
  vertical-align: middle;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}









