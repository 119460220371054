// Rows for forms
.form {
  &__row {
    display: flex;
    flex-direction: row;
    width: 100%;

    & > * {
      display: inline-flex;
    }

    &--h {
      flex-wrap: nowrap;
      justify-content: space-between;

      &-50 {
        & > * {
          flex: auto;
          margin-left: 5px;
          margin-right: 5px;
        }
      }
    }

    &--v {
      flex-wrap: wrap;

      & > * {
        flex: 0 0 100%;
      }

      .field + .field {
        margin-top: 10px;
      }

      .btn {
        width: 100%;
      }
    }
  }

  &__row + &__row {
    margin-top: 15px;
  }
}
