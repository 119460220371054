// Алерт
.alert {
  width: 100%;
  min-width: 320px;
  padding: 10px 10px 20px 10px;
  font-size: $font-size;
  font-weight: $font-weight-medium;
  text-align: left;
  position: fixed;
  left: 0;
  bottom: 0;
  z-index: z('alert');

  &__close {
    width: 12px;
    height: 12px;
    font-size: 14px;
    position: absolute;
    top: 5px;
    right: 10px;
    text-align: center;
    line-height: 12px;
    cursor: pointer;
  }

  &--error {
    color: $color-red-dark;
    background-color: $color-red-light;

    .alert__close {
      color: $color-red-dark;
    }
  }
}

// Обертка кнопки
.btn-wrap {
  width: 100%;

  .btn {
    margin: 0 auto;
  }
}
