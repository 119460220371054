table.table {
  width: 100%;
  color: $color-black;
  background: $color-white;
  border: 1px solid $color-borders;
  font-size: $font-size-smaller;
  border-collapse: collapse;

  thead th,
  tfoot th {
    color: $color-black-light;
    text-align: left;
    background: $color-gray-very-light;
  }

  caption {
    padding: .5em;
  }

  th,
  td {
    background: $color-white;
    border: 1px solid $color-border-light;
    word-wrap: normal;

    // Table cell min-width hack
    &:after {
      content: ' ';
      display: none;
      height: 0;
    }
  }

  th,
  td {
    padding: .5em;
  }

  @mixin sticky {
    position: sticky;
    left: 0;
    z-index: 2;
  }

  &--sticky {
    border-collapse: separate;

    thead {
      th:first-of-type {
        @include sticky;
      }
    }
    tr{
      td:first-of-type {
        @include sticky;
      }
    }
  }

  &--zebra {
    tbody tr:not(:only-child):nth-of-type(even) td {
      background: $color-gray-very-light;
    }
  }

  .__cell-width-small {
    &:after {
      display: block;
      width: 8em;
    }
  }

  .__cell-width-medium {
    &:after {
      display: block;
      width: 12em;
    }
  }

  .__cell-width-large {
    &:after {
      display: block;
      width: 14em;
    }
  }

  .__cell-width-very-large {
    &:after {
      display: block;
      width: 24em;
    }
  }
}

.table-section {
  width: 100%;
  max-width: 100%;
  overflow-x: auto;
}


