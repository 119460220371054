.panel {
  position: relative;
  border-radius: $border-radius-medium;
  border: 1px solid;
  padding: $padding-regular $padding-medium;
  margin-bottom: 18px;
  background-color: $color-white;
  color: $dark-panel-100;
  border-color: $color-borders;

  &__group {
    flex-flow: row wrap;
    position: relative;
  }

  .panel__group + .panel__group {
    margin-top: 20px;
  }

  ul {
    margin: 0;
  }

  //&__item {
  //
  //}

  // Panel types
  &--dark {
    background-color: $color-gray-500;
    color: $dark-panel-100;
    border-color: $dark-panel-200;
  }

  &--w-shadow {
    border: none;
    box-shadow: 0 1px 10px rgba($color-black, 0.25);
  }

  &--w-colored-shadow {
    border: none;
    box-shadow: 0 1px 10px rgba($color-general-dark, 0.25);
  }

  &--transparent {
    border: none;
    background-color: transparent;
  }
}
