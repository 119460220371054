// Tags

:host {
  font-size: $font-size-smaller;
}

.tag {
  display: inline-block;
  vertical-align: middle;
  padding: 0 0.4em;
  border: 1px solid $color-borders;
  font-size: 1em;
  color: $color-black-light;
  border-radius: 4px;
  cursor: pointer;
  user-select: none;
  background-color: $color-white;
  margin: 0 5px 5px 0;

  &__inner {
    display: block;
    width: 100%;
    min-width: 10ch;
    max-width: 50ch; // 50 symbols
  }

  &:not(.tag--multiline) {
    .tag__inner {
      line-height: 2.16em;
      @include text-overflow;
      text-align: center;
    }
  }

  &--multiline {
    .tag__inner {
      width: 210px;
      line-height: 1.5;
      text-align: left;
    }
  }

  // Class for skeleton screens
  &.__skeletal {
    height: 26px;
    min-width: 100px;
  }

  &:not(.__skeletal):not(.__active) {
    &:hover {
      color: rgba($color-general, .85);
    }
  }

  &.__active:not(.__disabled) {
    border-color: $color-general;
    color: $color-general;
  }

  &.__disabled {
    opacity: .5;
  }
}

