@keyframes fadeIn {
  0% {
    opacity: 0
  }

  100% {
    opacity: 1
  }
}

.fade-in {
  animation: fadeIn 200ms 1
}

@keyframes fadeInHalf {
  0% {
    opacity: 0
  }

  100% {
    opacity: 0.5
  }
}

.fade-in-half {
  animation: fadeInHalf 200ms 1
}

@keyframes fadeInFull {
  0% {
    opacity: 0.5
  }

  100% {
    opacity: 1
  }
}

.fade-in-full {
  animation: fadeInFull 200ms 1
}

.animation-container {
  height: 40px;
  overflow: hidden
}

.animation-container.animation-container-small {
  height: 12px
}

.animation-container.animation-container-right .skeleton-line-2 {
  left: 0;
  right: 150px
}

@keyframes blockTextShine {
  0% {
    transform: translateX(-468px)
  }

  100% {
    transform: translateX(468px)
  }
}

.slide-down-enter-active {
  transition: transform 0.2s
}

.slide-down-enter,.slide-down-leave-to {
  transform: translateY(-30%)
}

@keyframes spin {
  0% {
    transform: rotate(0deg)
  }

  100% {
    transform: rotate(360deg)
  }
}

.transform-origin-center {
  -webkit-transform-origin: 50% 50%;
  transform-origin: 50% 50%
}

.animate-n-spin {
  -webkit-animation-name: spin;
  animation-name: spin
}

.animate-c-infinite {
  -webkit-animation-iteration-count: infinite;
  animation-iteration-count: infinite
}

.animate-t-linear {
  -webkit-animation-timing-function: linear;
  animation-timing-function: linear
}

.animate-d-1 {
  -webkit-animation-duration: 1s;
  animation-duration: 1s
}

.animate-d-2 {
  -webkit-animation-duration: 2s;
  animation-duration: 2s
}

.gl-spinner {
  -webkit-animation-name: spin;
  animation-name: spin;
  -webkit-animation-iteration-count: infinite;
  animation-iteration-count: infinite;
  -webkit-animation-timing-function: linear;
  animation-timing-function: linear;
  -webkit-animation-duration: 1s;
  animation-duration: 1s;
  transform-origin: 50% 50%
}

@keyframes slide-in-fwd-bottom {
  0% {
    transform: translateZ(-1400px) translateY(800px);
    opacity: 0
  }

  100% {
    transform: translateZ(0) translateY(0);
    opacity: 1
  }
}

.slide-in-fwd-bottom-enter-active {
  animation: slide-in-fwd-bottom 0.4s cubic-bezier(0.25, 0.46, 0.45, 0.94) both
}

.slide-in-fwd-bottom-leave-active {
  animation: slide-in-fwd-bottom 0.4s cubic-bezier(0.25, 0.46, 0.45, 0.94) both reverse
}

.fade-enter-active,.fade-leave-active,.fade-in-enter-active,.fade-out-leave-active {
  transition: opacity 0.3s linear
}

.fade-enter,.fade-in-enter,.fade-out-leave-to,.fade-leave-to {
  opacity: 0
}
