// Skeleton screen styles are transferred to the component

// Modifier class that makes an unloaded element a placeholder
.__skeletal {
  font-size: 0;
  color: $color-gray;
  font-weight: $font-weight-regular;
  background-color: $color-gray-soft;

  * {
    display: none;
  }
}






