/*
 * Миксины для медиа-запросов
 */

// АДАПТИВНАЯ СЕТКА - МЕДИАЗАПРОСЫ
$n: $max-scroll-width; // средняя ширина скролла
//$widescreen: $widescreen + $gutter + $n;
//$desktop: $desktop + $gutter + $n;
//$tablet: $tablet + $gutter + $n;
//$phablet: $phablet + $gutter + $n;
//$smartphone: $smartphone + $gutter + $n;

/* Основные разрешения
================================================*/

/* Desktop - widescreen
------------------------------------------------*/
@mixin resolution_widescreen {
  @media only screen and (min-width: $widescreen) {
    @content;
  }
}

/* Desktop - small desktop
------------------------------------------------*/
@mixin resolution_desktop {
  @media only screen and (min-width: $desktop) and (max-width: $widescreen - .02px) {
    @content;
  }
}
/* Mobile - tablet
------------------------------------------------*/
@mixin resolution_tablet {
  @media only screen and (min-width: $tablet) and (max-width: $desktop - .02px) {
    @content;
  }
}
/* Mobile - smartphone (Portrait, 320px)
------------------------------------------------*/
@mixin resolution_smartphone {
  @media only screen and (max-width: $tablet - .02px) {
    @content;
  }
}

/* Дополнительные разрешения
================================================*/

/* Mobile - phone only (Optional, 320px)
------------------------------------------------*/
@mixin resolution_phone {
  @media only screen and (max-width: $phablet - .02px) {
    @content;
  }
}

/* Mobile - phablet (Optional, 480px)
------------------------------------------------*/
@mixin resolution_phablet {
  @media only screen and (min-width: $phablet) and (max-width: $tablet - .02px) {
    @content;
  }
}

/* Брейкпоинты, объединяющие несколько разрешений
------------------------------------------------*/

@mixin resolution_all-but-widescreen {
  @media only screen and (max-width: $widescreen - .02px) {
    @content;
  }
}

@mixin resolution_desktop-and-up {
  @media only screen and (min-width: $desktop) {
    @content;
  }
}

@mixin resolution_tablet-and-desktop {
  @media only screen and (min-width: $tablet) and (max-width: $widescreen - .02px) {
    @content;
  }
}

@mixin resolution_tablet-and-up {
  @media only screen and (min-width: $tablet) {
    @content;
  }
}

@mixin resolution_phablet-and-up {
  @media only screen and (min-width: $phablet) {
    @content;
  }
}

@mixin resolution_smartphone-and-tablet {
  @media only screen and (max-width: $desktop - .02px) {
    @content;
  }
}

@mixin resolution_phablet-and-tablet {
  @media only screen and (min-width: $phablet) and (max-width: $desktop - .02px) {
    @content;
  }
}

@mixin resolution_phablet-tablet-and-dektop {
  @media only screen and (min-width: $phablet) and (max-width: $widescreen - .02px) {
    @content;
  }
}
