$entrez-color: lighten(#369, 28%);
$geneage-color: lighten(#BA4200, 25%);
$uniprot-color: lighten(#5caecd, 15%);
$disgenet-color: lighten(#7774D1, 10%);
$biogrid-color: $color-orange-300;
$ensembl-color: #697ec9;
$open-genes-color: $color-general;

$brand-colors: (
  'entrez': $entrez-color,
  'geneage': $geneage-color,
  'uniprot': $uniprot-color,
  'disgenet': $disgenet-color,
  'biogrid': $biogrid-color,
  'ensembl': $ensembl-color,
  'open-genes': $open-genes-color,
);

@each $brand-name, $brand-color in $brand-colors {
  .__brand-color-#{$brand-name} {
    background-color: $brand-color;
  }
}

