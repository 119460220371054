@mixin fadeIn {
  animation-name: fadeIn;
  animation-duration: 1s;
  animation-fill-mode: both;
}
.fadeIn {
  @include fadeIn;
}
@keyframes fadeIn {
  0% {opacity: 0;}
  100% {opacity: 1;}
}