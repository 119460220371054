html {
  min-height: 460px
}

body {
  display: table;
  height: 100%;
  width: 100%;
  min-width: $smartphone;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  padding-top: $header-height;

  &, * {
    box-sizing: border-box;
  }

  app-root {
    display: grid;
    height: 100%;
    min-height: calc(100vh - #{$header-height});
    grid-template-rows: 0 auto min-content;
  }

  .preloader {
    display: none;
  }

  &:before { // overlay
    content: ' ';
    display: none;
    height: 100%;
    width: 100%;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: z('overlay');
    background-color: rgba($color-general-dark, .15);
    @include fadeIn;
  }

  &.body--still {
    overflow: hidden;
    @media screen and (min-device-width: $desktop) {
      padding-right: 15px; // add ltr support
    }

    &:before {
      display: block;
    }
  }

  &.body--search-on-main-page-is-active {
    @include resolution_smartphone-and-tablet {
      overflow: hidden;

      &:before {
        display: block;
      }
    }
  }

  &.body--loading {
    @extend .body--still;

    .preloader {
      display: block;
    }
  }
}

app-header,
.app-header { // the class is used on static pages
  display: block;
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: z('header');
}

.__hoverable {
  @include ripple;
}

.wrapper {
  width: 100%;
}

.container {
  text-align: left;
  position: relative;

  & > header {
    text-align: center;
  }
}

.items-section {
  display: block;
  width: 100%;
  margin: 10px 0;
}

.tabs {
  &__tabs {
    width: 100%;
    height: 50px;
    font-size: 0;
    white-space: nowrap;
    margin-bottom: 10px;
    border-bottom: 1px solid $color-borders;
  }

  &__tab {
    display: inline-block;
    height: 50px;
    font-size: 14px;
    color: $color-general;
    vertical-align: top;
    text-align: center;
    white-space: normal;
    word-wrap: break-word;
    line-height: normal;
    max-width: (300px / 3);
    position: relative;
    cursor: pointer;
    margin-bottom: -2px;
    padding: 8px 5px 16px 8px;

    &:before {
      content: ' ';
      display: block;
      height: 3px;
      background-color: $color-general;
      opacity: 0;
      position: absolute;
      bottom: 0;
      left: 5px;
      right: 5px;
      z-index: 0;
    }

    &--active:before,
    &:hover:before {
      opacity: 1;
    }
  }

  &__content {
    padding: 2px 10px;
    max-height: 200px;
    overflow: scroll;
  }
}

.hidden {
  display: none !important;
}

@keyframes animation__appear {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

table {
  border-collapse: collapse;
}

