$aligments: ('left', 'center', 'right');
$sizes: (0, 5, 10, 15, 20, 25, 30, 35, 40, 45, 50, 60, 80, 100, 120);

@each $size in $aligments {
  .__text-#{$aligments} {
    text-align: $aligments;
  }
}

@each $size in $sizes {
  .__m-#{$size} {
    margin: #{$size}px !important;
  }
  .__mt-#{$size} {
    margin-top: #{$size}px !important;
  }
  .__mb-#{$size} {
    margin-bottom: #{$size}px !important;
  }
  .__ml-#{$size} {
    margin-left: #{$size}px !important;
  }
  .__mr-#{$size} {
    margin-right: #{$size}px !important;
  }
  .__ml-minus-#{$size} {
    margin-left: -#{$size}px;
  }
  .__mr-minus-#{$size} {
    margin-right: -#{$size}px;
  }
}

@each $size in $sizes {
  .__p-#{$size} {
    padding: #{$size}px !important;
  }
  .__pt-#{$size} {
    padding-top: #{$size}px !important;
  }
  .__pb-#{$size} {
    padding-bottom: #{$size}px !important;
  }
  .__pl-#{$size} {
    padding-left: #{$size}px !important;
  }
  .__pr-#{$size} {
    padding-right: #{$size}px !important;
  }
}

@each $size in $sizes {
  .__w-#{$size} {
    width: #{$size}px;
  }
}

@each $size in $sizes {
  .__h-#{$size} {
    width: #{$size}px;
  }
}

.__w-auto {
  width: auto;
}


// фикс для таблиц и другуго контента, который расширяет страницу
.__w-content {
  @include resolution_widescreen {
    $a: ($widescreen / 12) * 3;
    max-width: calc(100vw - #{$a - $gutter});
  }

  @include resolution_all-but-widescreen {
    max-width: calc(100vw - #{$gutter});
  }
}

.__w-content-fluid {
  max-width: calc(100vw - #{$gutter + 5});
}

.__nowrap {
  white-space: nowrap;
}

@include resolution_desktop-and-up {
  .__only-on-mobile {
    display: none !important;
  }
}

@include resolution_smartphone-and-tablet {
  .__only-on-desktop {
    display: none !important;
  }
}

.__disabled {
  pointer-events: none;
}
