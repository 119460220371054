.news-page {
  &__news.col {
    font-size: 18px; // делаем блоки новостей крупнее

    // TODO: no encapsulated styles redefinition
    app-news-list .news__item {
      animation: fadeIn .5s linear forwards; // при рендеринге/подгрузке элемент мингнет
    }

    .news__skeleton {
      margin-top: 20px;
    }
  }
}


